<template>
  <div>
    <el-button type="danger" @click="confirmBatchDelete" :disabled="multipleSelection.length === 0">批量删除</el-button>
    <div class="table-wrapper">
      <el-table
        ref="multipleTable"
        :data="orders"
        style="width: 100%; margin-top: 20px;"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="ID" width="50" />
        <el-table-column prop="order_number" label="订单号" />
        <el-table-column
          prop="upstream_supplier"
          label="上游厂家"
          :formatter="row => supplierMap[row.upstream_supplier]"
        />
        <el-table-column prop="applied_product" label="申请产品" />
        <el-table-column prop="applied_domain" label="申请域名" />
        <el-table-column prop="brand" label="品牌" />
        <el-table-column
          prop="certificate_type"
          label="证书类型"
          :formatter="row => certificateTypeMap[row.certificate_type]"
        />
        <el-table-column prop="domain_count" label="域名数量" />
        <el-table-column prop="application_email" label="申请邮箱" />
        <el-table-column prop="application_price" label="申请价格" />
        <el-table-column prop="certificate_duration" label="证书时长" />
        <el-table-column
          prop="status"
          label="状态"
          :formatter="row => statusMap[row.status]"
        />
        <el-table-column prop="created_at" label="创建时间" />
        <el-table-column prop="updated_at" label="更新时间" />
        <el-table-column label="操作">
          <template #default="scope">
            <div class="action-buttons">
              <el-button size="mini" type="danger" @click="confirmDelete(scope.row.id)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="custom-pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pagination.total"
        @current-change="handlePageChange"
        :page-size="pagination.pageSize"
        :current-page="pagination.page"
        style="margin-top: 20px; justify-content: center;"
      />
    </div>
    <ConfirmDialog
      v-model:visible="confirmDialogVisible"
      message="确定要删除这个订单吗？"
      @confirm="handleDelete"
      @cancel="confirmDialogVisible = false"
    />
    <ConfirmDialog
      v-model:visible="confirmBatchDialogVisible"
      message="确定要删除选中的订单吗？"
      @confirm="handleBatchDelete"
      @cancel="confirmBatchDialogVisible = false"
    />
  </div>
</template>

<script>
import api from '@/api'; // 引入已配置好的api实例
import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
  name: 'OrdersList',
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      orders: [],
      multipleSelection: [],
      confirmDialogVisible: false,
      confirmBatchDialogVisible: false,
      currentDeleteId: null,
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      // 定义状态映射
      statusMap: {
        1: '待支付',
        2: '待申请',
        3: '待验证',
        4: '待签发',
        5: '签发成功',
      },
      // 定义上游厂家映射
      supplierMap: {
        1: 'Certbase',
        2: '锐成',
        // 根据具体需求添加其他厂家
      },
      // 定义证书类型映射
      certificateTypeMap: {
        1: '单域名',
        2: '通配符',
      },
    };
  },
  created() {
    this.fetchOrders();
  },
  methods: {
    fetchOrders() {
      api.get('/api/orders', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        params: {
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
        }
      }).then(response => {
        const { data, total } = response.data;
        this.orders = data;
        this.pagination.total = total;
      }).catch(error => {
        console.error('获取订单列表失败', error);
        this.showAlert('error', '获取订单列表失败');
      });
    },
    handlePageChange(newPage) {
      this.pagination.page = newPage;
      this.fetchOrders();
    },
    confirmDelete(id) {
      this.currentDeleteId = id;
      this.confirmDialogVisible = true;
    },
    handleDelete() {
      if (this.currentDeleteId) {
        api.delete(`/api/orders/${this.currentDeleteId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }).then(() => {
          this.fetchOrders();
          this.showAlert('success', '删除订单成功');
        }).catch(error => {
          console.error('删除订单失败', error);
          this.showAlert('error', '删除订单失败');
        }).finally(() => {
          this.confirmDialogVisible = false;
          this.currentDeleteId = null;
        });
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    confirmBatchDelete() {
      this.confirmBatchDialogVisible = true;
    },
    handleBatchDelete() {
      const ids = this.multipleSelection.map(item => item.id);
      api.post('/api/orders/batch-delete', { ids }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(() => {
        this.fetchOrders();
        this.showAlert('success', '批量删除成功');
      }).catch(error => {
        console.error('批量删除失败', error);
        this.showAlert('error', '批量删除失败');
      }).finally(() => {
        this.confirmBatchDialogVisible = false;
        this.multipleSelection = [];
      });
    },
    showAlert(type, message) {
      this.$alert(message, '提示', {
        confirmButtonText: '确定',
        type: type
      });
    },
  },
};
</script>

<style scoped>
.action-buttons {
  display: flex;
  gap: 5px;
  white-space: nowrap;
}

.table-wrapper {
  overflow-x: auto;
  width: 100%;
}

.custom-pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.custom-pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.el-pagination {
  background-color: #ffffff00;
  padding: 10px;
  border-radius: 5px;
}
.el-pagination .el-pager li:not(.is-active) {
  background-color: #ffffff;
}
.custom-pagination >>> .el-pager .number.is-active {
  background-color: #626aef !important;
}
</style>
