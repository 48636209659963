<template>
  <div>
    <el-button color="#626aef" type="primary" @click="openAddDrawer">添加分类</el-button>
    <el-table :data="categories" style="width: 100%; margin-top: 20px;">
      <el-table-column prop="id" label="ID" width="50" />
      <el-table-column label="分类名称">
        <template #default="scope">
          <span :style="{ paddingLeft: `${scope.row.level * 20}px` }">
            {{ scope.row.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="slug" label="别名" />
      <el-table-column prop="description" label="描述" />
      <el-table-column label="父级分类">
        <template #default="scope">
          <span>{{ scope.row.parent ? scope.row.parent.name : '无' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间" />
      <el-table-column prop="updated_at" label="更新时间" />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button size="mini" @click="openEditDrawer(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="confirmDelete(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="custom-pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pagination.total"
        @current-change="handlePageChange"
        :page-size="pagination.pageSize"
        :current-page="pagination.page"
        style="margin-top: 20px; justify-content: center;"
      />
    </div>

    <!-- 添加分类抽屉 -->
    <el-drawer v-model="addDrawerVisible" title="添加分类" direction="rtl" size="30%">
      <template #default>
        <el-form :model="form">
          <el-form-item label="分类名称">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item label="分类别名">
            <el-input v-model="form.slug" />
          </el-form-item>
          <el-form-item label="分类描述">
            <el-input v-model="form.description" />
          </el-form-item>
          <el-form-item label="---关键词">
            <el-input v-model="form.kw" />
          </el-form-item>
          <el-form-item label="父级分类">
            <el-select v-model="form.parent_id" placeholder="选择父级分类">
              <el-option v-for="category in hierarchicalCategories" :key="category.id" :label="category.label" :value="category.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分类图标">
            <el-input v-model="form.sortimg" placeholder="图标 URL" />
            <el-button @click="showMediaLibrary" class="image-preview">选择图标</el-button>
            <div v-if="form.sortimg" class="image-preview">
              <img :src="form.sortimg" alt="分类图标预览" />
            </div>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="drawer-footer">
          <el-button color="#626aef" @click="addDrawerVisible = false" plain>取消</el-button>
          <el-button color="#626aef" type="primary" @click="handleAdd">确定</el-button>
        </div>
      </template>
    </el-drawer>

    <!-- 编辑分类抽屉 -->
    <el-drawer v-model="editDrawerVisible" title="编辑分类" direction="rtl" size="30%">
      <template #default>
        <el-form :model="form">
          <el-form-item label="分类名称">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item label="分类别名">
            <el-input v-model="form.slug" />
          </el-form-item>
          <el-form-item label="分类描述">
            <el-input v-model="form.description" />
          </el-form-item>
          <el-form-item label="---关键词">
            <el-input v-model="form.kw" />
          </el-form-item>
          <el-form-item label="父级分类">
            <el-select v-model="form.parent_id" placeholder="选择父级分类">
              <el-option v-for="category in hierarchicalCategories" :key="category.id" :label="category.label" :value="category.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分类图标">
            <el-input v-model="form.sortimg" placeholder="图标 URL" />
            <el-button @click="showMediaLibrary" class="image-preview">选择图标</el-button>
            <div v-if="form.sortimg" class="image-preview">
              <img :src="form.sortimg" alt="分类图标预览" />
            </div>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="drawer-footer">
          <el-button color="#626aef" @click="editDrawerVisible = false" plain>取消</el-button>
          <el-button color="#626aef" type="primary" @click="handleEdit">确定</el-button>
        </div>
      </template>
    </el-drawer>

    <!-- 媒体库对话框 -->
    <el-dialog title="选择媒体" v-model="mediaDialogVisible">
      <media-library :selectMode="true" @media-selected="handleMediaSelected" />
    </el-dialog>

    <!-- Custom Alert -->
    <CustomAlert v-if="alert.visible" :type="alert.type" :message="alert.message" @close="alert.visible = false" />

    <!-- Confirm Dialog -->
    <ConfirmDialog
      v-model:visible="confirmDialogVisible"
      message="确定要删除这个分类吗？"
      @confirm="handleDelete"
      @cancel="confirmDialogVisible = false"
    />
  </div>
</template>
<script>
import api from '@/api'; // 引入已配置好的api实例
import CustomAlert from '@/components/CustomAlert.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import MediaLibrary from '@/views/MediaLibrary.vue'; // 确保路径正确

export default {
  name: 'CategoryManager',
  components: {
    CustomAlert,
    ConfirmDialog,
    MediaLibrary
  },
  data() {
    return {
      categories: [],
      hierarchicalCategories: [],
      form: {
        id: '',
        name: '',
        slug: '',
        description: '',
        kw: '',
        parent_id: 0,
        sortimg: ''
      },
      addDrawerVisible: false,
      editDrawerVisible: false,
      mediaDialogVisible: false,
      confirmDialogVisible: false,
      currentDeleteId: null,
      alert: {
        visible: false,
        type: '',
        message: '',
      },
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  created() {
    this.fetchCategories();
  },
  methods: {
    fetchCategories() {
      api.get('/api/categories', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        params: {
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
        }
      }).then(response => {
        const { data, total } = response.data;
        this.pagination.total = total;
        const tree = this.buildCategoryTree(data);
        this.categories = this.flattenTree(tree);
        this.hierarchicalCategories = this.generateHierarchicalOptions(tree);
      }).catch(error => {
        console.error('获取分类列表失败', error);
        this.showAlert('error', '获取分类列表失败');
      });
    },

    handlePageChange(newPage) {
      this.pagination.page = newPage;
      this.fetchCategories();
    },

    showMediaLibrary() {
      this.mediaDialogVisible = true;
    },
    handleMediaSelected(media) {
      this.form.sortimg = media.filepath;
      this.mediaDialogVisible = false;
    },

    buildCategoryTree(flatCategories) {
      const categoryMap = new Map();
      flatCategories.forEach(category => {
        category.children = [];
        categoryMap.set(category.id, category);
      });

      const tree = [];
      flatCategories.forEach(category => {
        if (category.parent_id && categoryMap.has(category.parent_id)) {
          const parent = categoryMap.get(category.parent_id);
          parent.children.push(category);
        } else {
          tree.push(category);
        }
      });

      return tree;
    },

    flattenTree(tree, level = 0) {
      let flatList = [];
      tree.forEach(node => {
        node.level = level;
        flatList.push(node);
        if (node.children.length) {
          flatList = flatList.concat(this.flattenTree(node.children, level + 1));
        }
      });
      return flatList;
    },

    generateHierarchicalOptions(tree) {
      const buildOptions = (categories, prefix = '') => {
        let options = [];
        categories.forEach(category => {
          const label = `${prefix}${category.name}`;
          options.push({
            id: category.id,
            label: label
          });
          if (category.children && category.children.length > 0) {
            options = options.concat(buildOptions(category.children, `${prefix}│  `));
          }
        });
        return options;
      };
      return buildOptions(tree);
    },

    openAddDrawer() {
      this.form = {
        id: '',
        name: '',
        slug: '',
        description: '',
        kw: '',
        parent_id: 0,
        sortimg: ''
      };
      this.addDrawerVisible = true;
    },

    handleAdd() {
      api.post('/api/categories', this.form, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(() => {
        this.addDrawerVisible = false;
        this.fetchCategories();
        this.showAlert('success', '添加分类成功');
      }).catch(error => {
        console.error('添加分类失败', error);
        this.showAlert('error', '添加分类失败');
      });
    },

    openEditDrawer(category) {
      this.form = { ...category };
      this.editDrawerVisible = true;
    },

    handleEdit() {
      api.put(`/api/categories/${this.form.id}`, this.form, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(() => {
        this.editDrawerVisible = false;
        this.fetchCategories();
        this.showAlert('success', '编辑分类成功');
      }).catch(error => {
        console.error('编辑分类失败', error);
        this.showAlert('error', '编辑分类失败');
      });
    },

    confirmDelete(id) {
      this.currentDeleteId = id;
      this.confirmDialogVisible = true;
    },

    handleDelete() {
      if (this.currentDeleteId) {
        api.delete(`/api/categories/${this.currentDeleteId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }).then(() => {
          this.fetchCategories();
          this.showAlert('success', '删除分类成功');
        }).catch(error => {
          console.error('删除分类失败', error);
          this.showAlert('error', '删除分类失败');
        }).finally(() => {
          this.confirmDialogVisible = false;
          this.currentDeleteId = null;
        });
      }
    },

    showAlert(type, message) {
      this.alert = {
        visible: true,
        type,
        message,
      };
    },
  },
};
</script>


<style scoped>
.drawer-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.image-preview {
  margin-top: 10px;
}
.image-preview img {
  max-width: 100%;
  height: auto;
  border: 1px solid #ddd;
  padding: 5px;
  background-color: #f9f9f9;
}

.custom-pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.el-pagination {
  background-color: #ffffff00; /* 自定义背景色 */
  padding: 10px;
  border-radius: 5px;
}

.el-pagination .el-pager li:not(.is-active) {
  background-color: #ffffff; /* 设置非激活状态分页项的背景色 */
}

.custom-pagination >>> .el-pager .number.is-active {
  background-color: #626aef !important; /* 使用 !important 确保样式优先级 */
}
</style>
