<template>
    <div>
      <video :src="media.filepath" controls></video>
    </div>
  </template>
  
  <script>
  export default {
    props: ['media'],
  };
  </script>
  
  <style scoped>
  video {
    width: 100%;
    height: 150px; /* 固定高度 */
    object-fit: cover; /* 保持视频比例并裁剪 */
  }
  </style>
  