<!-- src/views/MainLayout.vue -->
<template>
    <CommonLayout>
      <router-view />
    </CommonLayout>
  </template>
  
  <script>
  import CommonLayout from '@/components/CommonLayout.vue';
  
  export default {
    name: 'MainLayout',
    components: {
      CommonLayout,
    },
  };
  </script>
  