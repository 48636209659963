<template>
  <div>
    <div class="header">
      <h2>媒体库</h2>
      <button @click="toggleUploadArea" class="add-button">添加文件</button>
    </div>
    <div v-if="showUploadArea" class="upload-container">
      <input type="file" ref="fileInput" @change="uploadFiles" class="upload-input" multiple />
      <div class="upload-area"
           @dragover.prevent
           @dragenter.prevent
           @dragleave="dragLeave"
           @drop.prevent="handleDrop"
           @dragenter="dragEnter">
        <span class="upload-text">拖文件至此可上传</span>
        <span class="upload-or">或</span>
        <button @click="triggerFileInput" class="upload-button">选择文件</button>
        <p class="upload-size">最大上传文件大小: 100 MB</p>
      </div>
    </div>
    <div class="media-controls">
      <div class="controls-group">
        <select v-model="filter" class="custom-select" @change="fetchMediaList">
          <option value="">所有多媒体项目</option>
          <option v-for="type in mediaTypes" :key="type.mimetype" :value="type.mimetype">{{ type.mimetype }}</option>
        </select>
        <input type="date" v-model="dateFilter" class="custom-select" @change="fetchMediaList" />
        <button @click="deleteSelected" class="deletep-button">批量删除</button>
      </div>
      <input type="text" v-model="searchQuery" placeholder="搜索" class="search-box" @keyup.enter="fetchMediaList" />
    </div>
    <div v-if="mediaList.length" class="media-library">
      <div class="media-grid">
        <div v-for="media in mediaList" :key="media.id" class="media-item" @click="selectMedia(media)">
          <component :is="getMediaComponent(media)" :media="media" />
          <div class="media-info">
            <p class="filename">{{ media.filename }}</p>
            <p class="filetime">时间：{{ formatDate(media.addtime) }}</p>
            <p class="filesize">文件大小：{{ formatFileSize(media.filesize) }}，图片尺寸：{{ media.width }}x{{ media.height }}</p>
            <button class="filepath" @click.stop="copyFilePath(media.filepath)">文件路径</button> 
          </div>
          <button class="delete-button" @click.stop="deleteMedia(media.id)">删除</button>
          <input type="checkbox" :value="media.id" v-model="selectedMedia" class="media-checkbox"/>  
        </div>  
      </div>

      <el-pagination 
  background 
  v-if="total > 0"
  layout="prev, pager, next"
  :total="total"
  :page-size="pageSize"
  v-model:currentPage="page"
  @current-change="handlePageChange"
  class="custom-pagination">
</el-pagination>


    </div>
    <div v-else class="no-media">
      <p>没找到媒体内容</p>
    </div>
    <CustomAlert 
      v-if="alertMessage"
      :message="alertMessage"
      :type="alertType"
      @close="alertMessage = ''"
    />
    <ConfirmDialog 
      v-model:visible="showConfirmDialog"
      :message="confirmMessage"
      @confirm="handleConfirmDelete"
      @cancel="handleCancelDelete"
    />
  </div>




</template>

<script>
import api from '@/api'; // Adjust according to your project path
import CustomAlert from '@/components/CustomAlert.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import MediaImage from '@/components/MediaImage.vue';
import MediaVideo from '@/components/MediaVideo.vue';
import MediaIcon from '@/components/MediaIcon.vue';

export default {
  name: 'MediaLibrary',
  components: {
    CustomAlert,
    ConfirmDialog,
    MediaImage,
    MediaVideo,
    MediaIcon
  },
  props: {
    selectMode: Boolean
  },
  data() {
    return {
      selectedMedia: [],
      mediaList: [],
      mediaTypes: [],
      filter: '',
      dateFilter: '',
      searchQuery: '',
      showUploadArea: false,
      dragging: false,
      alertMessage: '',
      alertType: 'info',
      page: 1,
    pageSize: 10,
    total: 0,
      showConfirmDialog: false,
      confirmMessage: '',
      confirmAction: null,
    };
  },
  methods: {
    selectMedia(media) {
      this.$emit('media-selected', media);
    },
    async uploadFiles(event) {
      const files = event.target.files;
      if (!files.length) return;
      await this.uploadMultiple(files);
    },
    async uploadMultiple(files) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('file[]', files[i]);
      }

      try {
        const token = localStorage.getItem('token');
        const response = await api.post('/api/MediaController/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          },
        });
        this.mediaList.unshift(...response.data.data);
        this.alertMessage = '上传成功';
        this.alertType = 'success';
        this.clearFileInput();
      } catch (error) {
        console.error('上传失败', error);
        this.alertMessage = '上传失败';
        this.alertType = 'error';
      }
    },
    async fetchMediaList() {
    try {
      const token = localStorage.getItem('token');
      const response = await api.get('/api/MediaController/index', {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          page: this.page,
          pageSize: this.pageSize,
          filter: this.filter,
          dateFilter: this.dateFilter,
          searchQuery: this.searchQuery,
        }
      });
      console.log('API Response:', response.data); // 打印 API 返回数据
      this.mediaList = response.data.data;
      this.total = response.data.total;
      console.log('Fetched media list:', this.mediaList); // 调试信息
      console.log('Total items:', this.total); // 调试信息
      console.log('Current page:', this.page); // 调试信息
    } catch (error) {
      console.error('获取媒体列表失败', error);
    }
  },
 
    async fetchMediaTypes() {
      try {
        const token = localStorage.getItem('token');
        const response = await api.get('/api/MediaController/mediaTypes', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.mediaTypes = response.data.data;
      } catch (error) {
        console.error('获取媒体类型失败', error);
      }
    },
    async deleteMedia(id) {
      this.confirmMessage = '确定要删除这个文件吗？';
      this.confirmAction = () => this.performDeleteMedia(id);
      this.showConfirmDialog = true;
    },
    async deleteSelected() {
      this.confirmMessage = '确定要删除选中的文件吗？';
      this.confirmAction = this.performDeleteSelected;
      this.showConfirmDialog = true;
    },
    async performDeleteMedia(id) {
      try {
        const token = localStorage.getItem('token');
        await api.post('/api/MediaController/delete', { id }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.mediaList = this.mediaList.filter(media => media.id !== id);
        this.alertMessage = '删除成功';
        this.alertType = 'success';
      } catch (error) {
        console.error('删除失败', error);
        this.alertMessage = '删除失败';
        this.alertType = 'error';
      }
    },
    async performDeleteSelected() {
      try {
        const token = localStorage.getItem('token');
        await api.post('/api/MediaController/batchDelete', { ids: this.selectedMedia }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.mediaList = this.mediaList.filter(media => !this.selectedMedia.includes(media.id));
        this.selectedMedia = [];
        this.alertMessage = '批量删除成功';
        this.alertType = 'success';
      } catch (error) {
        console.error('批量删除失败', error);
        this.alertMessage = '批量删除失败';
        this.alertType = 'error';
      }
    },
    clearFileInput() {
      this.$refs.fileInput.value = null;
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    toggleUploadArea() {
      this.showUploadArea = !this.showUploadArea;
    },
    handleDrop(event) {
      const files = event.dataTransfer.files;
      this.uploadMultiple(files);
      this.dragging = false;
    },
    dragEnter() {
      this.dragging = true;
    },
    dragLeave() {
      this.dragging = false;
    },
    copyFilePath(filepath) {
      navigator.clipboard.writeText(filepath).then(() => {
        this.alertMessage = '文件路径已复制';
        this.alertType = 'success';
      }).catch((error) => {
        console.error('复制失败', error);
        this.alertMessage = '复制失败';
        this.alertType = 'error';
      });
    },
    formatDate(timestamp) {
      const date = new Date(timestamp * 1000);
      return date.toLocaleString();
    },
    formatFileSize(size) {
      if (size < 1024) return `${size} B`;
      else if (size < 1048576) return `${(size / 1024).toFixed(2)} KB`;
      else return `${(size / 1048576).toFixed(2)} MB`;
    },
    getMediaComponent(media) {
      const fileType = media.filename.split('.').pop().toLowerCase();
      if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileType)) {
        return 'MediaImage';
      } else if (['mp4', 'avi', 'mov', 'wmv', 'flv'].includes(fileType)) {
        return 'MediaVideo';
      } else if (['zip', 'rar', '7z'].includes(fileType)) {
        return 'MediaIcon';
      } else {
        return 'MediaIcon';
      }
    },
    handlePageChange(page) {
      this.page = page;
      this.fetchMediaList();
    },

    handleConfirmDelete() {
      if (this.confirmAction) this.confirmAction();
    },
    handleCancelDelete() {
      this.confirmMessage = '';
      this.confirmAction = null;
    },
  },
  watch: {
    filter() {
      this.fetchMediaList();
    },
    dateFilter() {
      this.fetchMediaList();
    }
  },
  mounted() {
    this.fetchMediaList();
    this.fetchMediaTypes();
  },
};
</script>


<style scoped>
.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.header h2 {
  margin-right: 20px; /* 调整这个值以增加标题和按钮之间的距离 */
  font-weight: 400;
}
.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.upload-input {
  display: none;
}
.upload-area {
  border: 4px dashed #ccc;
  padding: 20px;
  width: 100%;
  text-align: center;
}
.upload-area.dragging {
  border-color: #626aef;
}
.upload-text,
.upload-or,
.upload-button,
.upload-size {
  display: block;
  margin-bottom: 10px;
}

.upload-or {
  font-size: 12px;
}

.upload-size {
  font-size: 12px;
  margin-top: 15px;
}
.upload-button {
  margin: 0 auto; /* Center the button */
  background-color: white;
  border: 1px solid #626aef;
  color: #626aef;
  padding: 12px 36px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.media-library {
  width: 100%;
  margin: 0 auto;
}
.media-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.controls-group {
  display: flex;
  align-items: center;
}
.controls-group .custom-select,
.controls-group .delete-button {
  margin-right: 10px;
}
.custom-select {
  padding: 5px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}
.search-box {
  margin-left: auto;
  flex-grow: 1;
  max-width: 300px;
}
.media-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* 设置项目之间的间距 */
}
.media-item {
  border: 1px solid #ddd;
  padding: 10px;
  flex: 1 1 calc(25% - 20px); /* 确保每行显示4个项目，并减去间距 */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* 为了定位删除按钮和复选框 */
}
.media-item img,
.media-item video {
  width: 100%;
  height: 150px; /* 固定高度 */
  object-fit: cover; /* 保持图片比例并裁剪 */
}
.media-info {
  width: 100%;
  text-align: left; /* 文字左对齐 */
  padding: 10px 0; /* 上下内边距 */
}
.media-info p {
  margin: 5px 0; /* 确保各项间距一致 */
  font-size: 14px;
}
.filename {
  font-weight: bold;
}
.filetime,
.filesize {
  color: #555;
}

.filepath {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  font-size: 14px;
  display: block; /* 显示在单独的一行 */
  margin-top: 5px;
}
.delete-button {
  color: red;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  position: absolute; /* 绝对定位 */
  left: 10px; /* 调整这个值以改变位置 */
}

.media-checkbox {
  position: absolute; /* 绝对定位 */
  right: 10px; /* 调整这个值以改变位置 */
}

.deletep-button,
.add-button {
  background-color: white;
  border: 1px solid #626aef;
  color: #626aef;
  padding: 4px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.add-button:hover {
  background-color: #626aef;
  color: white;
}

.custom-pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.el-pagination {
  background-color: #ffffff00; /* 自定义背景色 */
  padding: 10px;
  border-radius: 5px;
}

.el-pagination .el-pager li:not(.is-active) {
  background-color: #ffffff; /* 设置非激活状态分页项的背景色 */
}

.custom-pagination >>> .el-pager .number.is-active {
  background-color: #626aef !important; /* 使用 !important 确保样式优先级 */
}



input {
    width: auto;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    box-sizing: border-box;
}


.no-media {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  color: #555;
}


</style>
