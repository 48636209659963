<template>
  <div>
    <el-form :model="options" label-width="120px" label-position="left">
      <el-form-item label="站点标题">
        <el-input v-model="options.site_title"></el-input>
        <span class="remark">请输入站点的主标题</span>
      </el-form-item>
      <el-form-item label="副标题">
        <el-input v-model="options.subtitle"></el-input>
        <span class="remark">请输入站点的副标题</span>
      </el-form-item>

      <el-form-item label="文字logo">
        <el-input v-model="options.logotitle"></el-input>
        <span class="remark">请输入文字logo</span>
      </el-form-item>


      <el-form-item label="网站描述">
        <el-input v-model="options.description"></el-input>
        <span class="remark">请输入站点SEO描述</span>
      </el-form-item>


      <el-form-item label="网站关键词">
        <el-input v-model="options.keywords"></el-input>
        <span class="remark">请输入站点SEO关键词</span>
      </el-form-item>



      <el-form-item label="客服二维码">
        <div class="icon-wrapper">
          <el-button @click="openMediaLibrary('site_icon')" class="custom-button">选择一个</el-button>
          <img v-if="options.site_icon" :src="options.site_icon" alt="客服二维码" class="site-icon">
          <span class="remark">左下角客服二维码  </span>
        </div>
      </el-form-item>

      <el-form-item label="网站LOGO">
        <div class="logo-wrapper">
          <el-button @click="openMediaLibrary('site_logo')" class="custom-button">选择一个站点LOGO</el-button>
          <img v-if="options.site_logo" :src="options.site_logo" alt="网站LOGO" class="site-logo">
          <span class="remark">网站前台展示的LOGO</span>
        </div>
      </el-form-item>

 

      <el-form-item label="站点地址 (URL)">
        <el-input v-model="options.site_url"></el-input>
        <span class="remark">请输入站点的 URL 地址</span>
      </el-form-item>
     
      <el-form-item label="成员资格">
        <el-checkbox v-model="membershipChecked">任何人都可以注册</el-checkbox>
      </el-form-item>

      <el-form-item label="ICP 备案号">
        <el-input v-model="options.site_icp"></el-input>
        <span class="remark">仅对部分支持的主题生效。</span>
      </el-form-item>

      <el-form-item label="公安备案号">
        <el-input v-model="options.site_ga"></el-input>
        <span class="remark">仅对部分支持的主题生效（需自行添加公安图标）。</span>
      </el-form-item>


      <el-form-item label="版权信息">
        <el-input v-model="options.copyright"></el-input>
        <span class="remark">底部版权信息</span>
      </el-form-item>


      <el-form-item>
        <el-button color="#626aef" type="primary" @click="saveOptions">保存设置</el-button>
      </el-form-item>
    </el-form>

    <!-- Custom Alert -->
    <CustomAlert v-if="alert.visible" :type="alert.type" :message="alert.message" @close="alert.visible = false" />

    <!-- 媒体库对话框 -->
    <el-dialog title="选择媒体" v-model="mediaDialogVisible">
      <MediaLibrary :selectMode="true" @media-selected="handleMediaSelected" />
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'; // 引入已配置好的api实例
import CustomAlert from '@/components/CustomAlert.vue';
import MediaLibrary from '@/views/MediaLibrary.vue'; // 引入媒体库组件

export default {
  name: 'SettingGeneral',
  components: {
    CustomAlert,
    MediaLibrary
  },
  data() {
    return {
      options: {
        site_title: '',
        subtitle: '',
        site_icon: '',
        site_logo: '',
        site_url: '',
        site_icp: '',
        logotitle: '',
        copyright: '',
        site_ga: '',
        membership: 'n'
      },
      alert: {
        visible: false,
        type: '',
        message: ''
      },
      mediaDialogVisible: false,
      currentMediaType: ''
    };
  },
  computed: {
    membershipChecked: {
      get() {
        return this.options.membership === 'y';
      },
      set(value) {
        this.options.membership = value ? 'y' : 'n';
      }
    }
  },
  created() {
    this.fetchOptions();
  },
  methods: {
    fetchOptions() {
      api.post('/api/General', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(response => {
        this.options = this.mapOptions(response.data.data);
      }).catch(error => {
        console.error('获取配置项失败', error);
        this.showAlert('error', '获取配置项失败');
      });
    },
    mapOptions(data) {
      const mappedOptions = {};
      data.forEach(option => {
        mappedOptions[option.option_name] = option.option_value;
      });
      return mappedOptions;
    },
    openMediaLibrary(mediaType) {
      this.currentMediaType = mediaType;
      this.mediaDialogVisible = true;
    },
    handleMediaSelected(media) {
      if (this.currentMediaType === 'site_icon') {
        this.options.site_icon = media.filepath;
      } else if (this.currentMediaType === 'site_logo') {
        this.options.site_logo = media.filepath;
      }
      this.mediaDialogVisible = false;
    },
    saveOptions() {
    const promises = Object.keys(this.options).map(option_name => {
      const option_value = this.options[option_name];
      return api.post('/api/saveOption', {
        option_name,
        option_value
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
    });

    Promise.all(promises)
      .then(() => {
        this.showAlert('success', '配置项保存成功');
      })
      .catch(error => {
        console.error('保存配置项失败', error);
        this.showAlert('error', '保存配置项失败');
      });
  },

    showAlert(type, message) {
      this.alert.type = type;
      this.alert.message = message;
      this.alert.visible = true;
    }
  }
};
</script>

<style scoped>

.custom-button:hover {
  border-color: #626aef; /* 这里替换成你想要的颜色 */
  color: #626aef; /* 如果需要改变字体颜色 */
}

.logo-wrapper {
  display: flex;
  flex-direction: column;
}

.site-logo {
  margin-top: 10px;
  width: 100px;
  height: auto;
}

.icon-wrapper {
  display: flex;
  flex-direction: column;
}

.site-icon {
  margin-top: 10px;
  width: 100px;
  height: auto;
}

.remark {
  display: block;
  font-size: 13px;
  color: #999;
}
</style>
