<template>
    <div v-if="visible" class="confirm-dialog-overlay">
      <div class="confirm-dialog">
        <p>提示</p>
        <p>{{ message }}</p>
        <div class="confirm-dialog-buttons">
          <button class="cancel-button" @click="cancel">{{ cancelButtonText }}</button>
          <button class="confirm-button" @click="confirm">{{ confirmButtonText }}</button>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    props: {
      message: {
        type: String,
        default: '{{ message }}'
      },
      confirmButtonText: {
        type: String,
        default: '确定'
      },
      cancelButtonText: {
        type: String,
        default: '取消'
      },
      visible: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      confirm() {
        this.$emit('confirm');
        this.close();
      },
      cancel() {
        this.$emit('cancel');
        this.close();
      },
      close() {
        this.$emit('update:visible', false);
      }
    }
  };
  </script>
  
  <style scoped>
.confirm-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.confirm-dialog {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 400px;
  max-width: 80%;
}

.confirm-dialog p:first-child {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

.confirm-dialog p:last-child {
  margin-bottom: 20px;
}

.confirm-dialog-buttons {
  display: flex;
  justify-content: center;
}

.cancel-button, .confirm-button {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.cancel-button {
    margin-top: 20px;
  background: #f5f5f5;
  margin-right: 20px;
}

.confirm-button {
    margin-top: 20px;
  background: #626aef;
  color: white;
}
</style>