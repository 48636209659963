<template>
    <el-container>
        <el-header>
            <h2>个人信息</h2>
        </el-header>
        <el-main>
            <el-form :model="userForm" ref="userForm" label-width="120px" @submit.prevent="onSubmit">
                <div class="profile-avatar" @click="triggerFileInput">
                    <input type="file" class="avatar-input" ref="avatarInput" @change="handleAvatarChange" />
                    <div class="avatar-container">
                        <img :src="userForm.avatar || defaultAvatar" class="avatar" />
                        <div v-if="!userForm.avatar" class="avatar-placeholder">修改</div>
                    </div>
                </div>
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="userForm.username" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input v-model="userForm.email" placeholder="请输入邮箱"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="password">
                    <el-input type="password" v-model="userForm.password" placeholder="请输入新密码"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="confirmPassword">
                    <el-input type="password" v-model="userForm.confirmPassword" placeholder="请再次输入新密码"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="phone">
                    <el-input v-model="userForm.phone" placeholder="请输入电话号码"></el-input>
                </el-form-item>
                <el-form-item label="描述" prop="description">
                    <el-input type="textarea" v-model="userForm.description" placeholder="请输入描述"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button color="#626aef" type="primary" @click="onSubmit">保存</el-button>
                </el-form-item>
            </el-form>
        </el-main>
    </el-container>
</template>

<script>
import api from '@/api';
import defaultAvatarImage from '@/assets/default-avatar.png'; // 确保你有一个默认头像图片在你的 assets 文件夹中

export default {
    name: 'UserProfile',
    data() {
        return {
            userForm: {
                username: '',
                email: '',
                password: '',
                confirmPassword: '',
                phone: '',
                description: '',
                avatar: '',
                avatarFile: null
            },
            defaultAvatar: defaultAvatarImage
        };
    },
    methods: {
        async fetchUserInfo() {
            try {
                const token = localStorage.getItem('token');
                const response = await api.get('/api/Profile/info', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                console.log(response); // 调试输出
                this.userForm = response.data;
                if (!this.userForm.avatar) {
                    this.userForm.avatar = ''; // 确保 avatar 为空字符串如果没有提供
                }
            } catch (error) {
                console.error(error); // 调试输出
                this.$message.error('可能token过期，获取用户信息失败，请重登陆尝试');
            }
        },
        handleAvatarChange(event) {
            const file = event.target.files[0];
            if (file) {
                const isImage = ['image/jpeg', 'image/png', 'image/gif'].includes(file.type);
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isImage) {
                    this.$message.error('上传头像图片只能是 JPG/PNG/GIF 格式!');
                    return;
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                    return;
                }

                this.userForm.avatarFile = file;
                this.userForm.avatar = URL.createObjectURL(file);
            }
        },
        triggerFileInput() {
            this.$refs.avatarInput.click();
        },
        async onSubmit() {
            if (this.userForm.password !== this.userForm.confirmPassword) {
                this.$message.error('两次输入的密码不一致');
                return;
            }

            try {
                const formData = new FormData();
                if (this.userForm.username) formData.append('username', this.userForm.username);
                if (this.userForm.email) formData.append('email', this.userForm.email);
                if (this.userForm.password) formData.append('password', this.userForm.password);
                if (this.userForm.phone) formData.append('phone', this.userForm.phone);
                if (this.userForm.description) formData.append('description', this.userForm.description);
                if (this.userForm.avatarFile) {
                    formData.append('avatar', this.userForm.avatarFile);
                }

                const token = localStorage.getItem('token');
                const response = await api.post('/api/Profile/update', formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response.data.message === '更新成功') {
                    this.$message.success('更新成功');
                } else {
                    this.$message.error('更新失败: ' + response.data.error);
                }
            } catch (error) {
                console.error(error); // 调试输出
                this.$message.error('更新失败');
            }
        }
    },
    mounted() {
        this.fetchUserInfo();
    }
};
</script>

<style>
.profile-avatar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    margin-left: 120px; /* 与标签宽度对齐 */
}
.avatar-input {
    display: none;
}
.avatar-container {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}
.avatar {
    width: 100%;
    height: 100%;
}
.avatar-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
    font-size: 14px;
    color: #666;
}
.avatar-container:hover .avatar-placeholder {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
}
.avatar-container::before {
    content: '修改';
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    line-height: 30px;
    display: none;
}
.avatar-container:hover::before {
    display: block;
}
</style>
