// src/api/api.js
import axios from 'axios';
import router from '@/router'; // 根据实际路径进行调整

const api = axios.create({
    baseURL: 'https://agent.dxdu.cn/',
    timeout: 1000,
});

api.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers['Authorization'] = token;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

api.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response && error.response.status === 401) {
        // Token 已过期，重定向到登录页面
        localStorage.removeItem('token');
        router.push('/login'); // 根据你的登录路由进行调整
    }
    return Promise.reject(error);
});

export default api;