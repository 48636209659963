<template>
    <div class="theme-settings-container">
      <div class="header">
        <div class="header-content">
          <h2>{{ theme.name }}</h2>
        </div>
        <el-button @click="goBack" type="danger">返回</el-button>
      </div>
      <div class="content">
        <el-menu class="el-menu-vertical custom-menu" default-active="0">
          <el-menu-item
            v-for="(category, index) in settingsCategories"
            :key="index"
            :index="index.toString()"
            @click="activeCategoryIndex = index"
          >
            {{ category.category }}
          </el-menu-item>
        </el-menu>
        <div class="form-container">
          <el-form :model="settings" label-width="120px" label-position="left" v-if="settingsCategories.length > 0">
            <el-form-item
              v-for="item in settingsCategories[activeCategoryIndex].settings"
              :key="item.key"
              :label="item.label"
            >
              <component
                :is="getComponentType(item.type)"
                v-model="settings[item.key]"
                :type="item.type"
                :options="item.options"
                :class="{'custom-checkbox-allow-remark': item.type === 'checkbox'}"
              ></component>
              <div v-if="item.type.startsWith('MediaLibrary')" class="button-remark-container">
                <div v-if="item.remark" class="remark">{{ item.remark }}</div>
                <el-button
                  color="#626aef"
                  type="primary"
                  @click="openMediaLibrary(item.key, item.type)"
                  class="select-button"
                >
                  选择 {{ item.type === 'MediaLibrary-image' ? '图片' : item.type === 'MediaLibrary-video' ? '视频' : '文件' }}
                </el-button>
                <div v-if="settings[item.key]" class="media-preview">
                  <img v-if="item.type === 'MediaLibrary-image'" :src="settings[item.key]" alt="Image preview" />
                  <video v-if="item.type === 'MediaLibrary-video'" :src="settings[item.key]" controls></video>
                  <div v-if="item.type === 'MediaLibrary-file'" class="file-icon">
                    <span>📁</span>
                    <a :href="settings[item.key]" target="_blank">{{ settings[item.key] }}</a>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-form>
          <el-form-item class="save-button-container">
            <el-button color="#626aef" type="primary" @click="saveSettings">保存设置</el-button>
          </el-form-item>
        </div>
      </div>
  
      <!-- 媒体库对话框 -->
      <el-dialog title="选择媒体" v-model="mediaDialogVisible" width="80%">
        <MediaLibrary :selectMode="true" @media-selected="handleMediaSelected" />
      </el-dialog>
    </div>
  </template>
  
  <script>
  import api from '@/api'; // 引入已配置好的api实例
  import MediaLibrary from '@/views/MediaLibrary.vue'; // 确保路径正确
  
  export default {
    name: 'ThemeSettings',
    components: {
      MediaLibrary
    },
    data() {
      return {
        theme: {},
        settingsCategories: [],
        settings: {},
        themeId: null,
        mediaDialogVisible: false,
        currentMediaKey: '',
        currentMediaType: '',
        activeCategoryIndex: 0
      };
    },
    created() {
      this.themeId = this.$route.params.id;
      this.fetchThemeDetails();
      this.fetchSettings();
    },
    methods: {
      fetchThemeDetails() {
        api.get(`/api/Themes/${this.themeId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }).then(response => {
          console.log('Theme details:', response.data.data); // 调试输出
          this.theme = response.data.data;
        }).catch(error => {
          console.error('提示', error);
        });
      },
      fetchSettings() {
        api.get(`/api/Themes/${this.themeId}/settings`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }).then(response => {
          console.log('Settings response:', response.data.data); // 调试输出
          this.settingsCategories = response.data.data;
          this.settingsCategories.forEach(category => {
            category.settings.forEach(item => {
              this.settings[item.key] = item.default;
            });
          });
        }).catch(error => {
          console.error('提示', error);
        });
      },
      getComponentType(type) {
        switch (type) {
          case 'text': return 'el-input';
          case 'color': return 'el-color-picker';
          case 'checkbox': return 'el-checkbox';
          default: return 'el-input';
        }
      },
      openMediaLibrary(key, type) {
        this.currentMediaKey = key;
        this.currentMediaType = type;
        this.mediaDialogVisible = true;
      },
      handleMediaSelected(media) {
        this.settings[this.currentMediaKey] = media.filepath;
        this.mediaDialogVisible = false;
      },
      saveSettings() {
        api.post(`/api/Themes/${this.themeId}/settings`, {
          settings: this.settings
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }).then(() => {
          this.$message.success('设置保存成功');
        }).catch(error => {
          console.error('提示', error);
          this.$message.error('保存设置失败');
        });
      },
      goBack() {
        this.$router.go(-1); // 返回上一页
      }
    }
  };
  </script>
  
  <style scoped>
  .theme-settings-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: linear-gradient(to right, #626aef, rgb(88, 124, 231)); /* 渐变背景色 */
    color: white;
    border-radius: 10px 10px 0 0; /* 上下两边为圆角 */
  }
  
  .header-content {
    display: flex;
    align-items: center;
  }
  
  .header-content h2 {
    margin: 0;
  }
  
  .header-buttons {
    display: flex;
    gap: 10px;
  }
  
  .content {
    display: flex;
    height: calc(100% - 84px); /* 84px is the height of the header and bottom margin */
  }
  
  .el-menu-vertical {
    width: 200px;
    background-color: #ffffff;
  }
  
  .custom-menu .el-menu-item:hover,
  .custom-menu .el-menu-item.is-active {
    background-color: #f8f8f8 !important; /* 自定义悬停和选中样式 */
    color: #626aef !important; /* 自定义选中字体颜色 */
  }
  
  .form-container {
    flex-grow: 1;
    padding: 20px;
  }
  
  .save-button-container {
    margin-top: 20px;
    text-align: right; /* 右对齐 */
  }
  
/* 自定义允许评论复选框选中颜色 */
::v-deep(.custom-checkbox-allow-remark .el-checkbox__input.is-checked .el-checkbox__inner) {
  background-color: #626aef !important;
  border-color: #626aef !important;
}
::v-deep(.custom-checkbox-allow-remark .el-checkbox__input.is-checked + .el-checkbox__label) {
  color: #626aef !important;
}
  
  .media-preview img,
  .media-preview video {
    max-width: 200px;
    margin-top: 10px;
  }
  
  .button-remark-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }
  
  .select-button {
    margin-bottom: 5px;
  }
  
  .file-icon {
    display: flex;
    align-items: center;
    font-size: 20px;
    margin-top: 10px;
  }
  
  .file-icon a {
    margin-left: 10px;
    text-decoration: underline;
    color: #409eff;
  }
  
  .remark {
    font-size: 12px;
    color: #999;
    margin-top: -15px;
  }
  </style>
  