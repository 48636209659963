<template>
  <h4>导航</h4>
  <div>
    <el-table :data="Nav" style="width: 100%; margin-top: 20px;">
      <el-table-column prop="id" label="ID" width="50" />
      <el-table-column label="导航名称">
        <template #default="scope">
          <span :style="{ paddingLeft: `${scope.row.level * 20}px` }">
            {{ scope.row.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="nav_type" label="类型">
        <template #default="scope">
          <span :style="getNavTypeStyle(scope.row.nav_type)">
            {{ getNavTypeLabel(scope.row.nav_type) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="url" label="地址" />
      <el-table-column prop="is_hidden" label="状态">
        <template #default="scope">
          <el-tag :type="scope.row.is_hidden ? 'danger' : 'success'">
            {{ scope.row.is_hidden ? '隐藏' : '显示' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button size="mini" @click="openEditDrawer(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="confirmDelete(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="custom-pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pagination.total"
        @current-change="handlePageChange"
        :page-size="pagination.pageSize"
        :current-page="pagination.page"
        style="margin-top: 20px; justify-content: center;"
      />
    </div>

    <div class="navigation-forms">
      <el-card class="box-card form-section">
        <h3>添加自定义导航</h3>
        <el-form :model="form">
          <el-form-item label="导航名称">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item label="地址 (URL)">
            <el-input v-model="form.url" />
          </el-form-item>
          <el-form-item label="在新窗口打开">
            <el-switch v-model="form.open_in_new_window" :active-value="true" :inactive-value="false" />
          </el-form-item>
          <el-form-item label="父导航">
            <el-select v-model="form.parent_id" placeholder="选择父导航">
              <el-option v-for="nav in filteredNav" :key="nav.id" :label="nav.name" :value="nav.id"></el-option>
            </el-select>
          </el-form-item>
          <el-button color="#626aef" type="primary" @click="handleAddCustomNav">保存</el-button>
        </el-form>
      </el-card>

      <el-card class="box-card form-section">
        <h3>添加分类到导航</h3>
        <el-form :model="categoryForm">
          <el-checkbox-group v-model="selectedCategories" class="vertical-checkbox-group">
            <el-checkbox v-for="category in categories" :label="category.id" :key="category.id" class="custom-checkbox-allow-remark">
              <span :style="{ paddingLeft: `${category.level * 20}px`, fontWeight: category.level > 0 ? 'bold' : 'normal' }">
                {{ category.level > 0 ? '┣ ' : '' }}{{ category.name }}
              </span>
            </el-checkbox>
          </el-checkbox-group>
          <el-button color="#626aef" type="primary" @click="addCategoriesToNav">保存</el-button>
          <el-link :underline="false" class="link-below-button" style="color: #626aef;" @click="navigateToCategoryManager">新建分类</el-link>
        </el-form>
      </el-card>

      <el-card class="box-card form-section">
        <h3>添加页面到导航</h3>
        <el-form :model="pageForm">
          <el-checkbox-group v-model="selectedPages" class="vertical-checkbox-group">
            <el-checkbox v-for="page in pages" :label="page.id" :key="page.id" class="custom-checkbox-allow-remark">{{ page.title }}</el-checkbox>
          </el-checkbox-group>
          <el-button color="#626aef" type="primary" @click="addPagesToNav">保存</el-button>
          <el-link :underline="false" class="link-below-button" style="color: #626aef;" @click="navigateToPageManager">添加页面</el-link>
        </el-form>
      </el-card>
    </div>

    <el-drawer v-model="editDrawerVisible" title="编辑导航" direction="rtl" size="30%">
      <template #default>
        <el-form :model="form">
          <el-form-item label="导航名称">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item label="✍ -地址" v-if="canEditUrl(form.nav_type)">
            <el-input v-model="form.url" />
          </el-form-item>
          <el-form-item label="新窗口打开">
            <el-switch v-model="form.open_in_new_window" :active-value="true" :inactive-value="false" />
          </el-form-item>
          <el-form-item label="是否隐藏">
            <el-switch v-model="form.is_hidden" :active-value="true" :inactive-value="false" />
          </el-form-item>
          <el-form-item label="☝ -- 排序">
            <el-input v-model="form.sort_order" />
          </el-form-item>
          <el-form-item label="父级导航" v-if="canEditUrl(form.nav_type)">
            <el-select v-model="form.parent_id" placeholder="选择父级导航">
              <el-option v-for="nav in filteredNav" :key="nav.id" :label="nav.name" :value="nav.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="导航图标">
            <el-input v-model="form.sortimg" placeholder="图标 URL" />
            <el-button @click="showMediaLibrary" class="image-preview">选择图标</el-button>
            <div v-if="form.sortimg" class="image-preview">
              <img :src="form.sortimg" alt="导航图标预览" />
            </div>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="drawer-footer">
          <el-button color="#626aef" @click="editDrawerVisible = false" plain>取消</el-button>
          <el-button color="#626aef" type="primary" @click="handleEdit">确定</el-button>
        </div>
      </template>
    </el-drawer>

    <el-dialog title="选择媒体" v-model="mediaDialogVisible">
      <media-library :selectMode="true" @media-selected="handleMediaSelected" />
    </el-dialog>

    <CustomAlert v-if="alert.visible" :type="alert.type" :message="alert.message" @close="alert.visible = false" />

    <ConfirmDialog
      v-model:visible="confirmDialogVisible"
      message="确定要删除这个导航吗？"
      @confirm="handleDelete"
      @cancel="confirmDialogVisible = false"
    />
  </div>
</template>

<script>
import api from '@/api';
import CustomAlert from '@/components/CustomAlert.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import MediaLibrary from '@/views/MediaLibrary.vue';

export default {
  name: 'NavBar',
  components: {
    CustomAlert,
    ConfirmDialog,
    MediaLibrary
  },
  data() {
    return {
      Nav: [],
      filteredNav: [],
      categories: [],
      pages: [],
      form: {
        id: '',
        name: '',
        url: '',
        open_in_new_window: false,
        is_hidden: false,
        sort_order: 0,
        parent_id: 0,
        sortimg: '',
        nav_type: 0,
        is_system_default: 0
      },
      categoryForm: {},
      pageForm: {},
      selectedCategories: [],
      selectedPages: [],
      addDrawerVisible: false,
      editDrawerVisible: false,
      mediaDialogVisible: false,
      confirmDialogVisible: false,
      currentDeleteId: null,
      alert: {
        visible: false,
        type: '',
        message: '',
      },
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  created() {
    this.fetchNav();
    this.fetchCategories();
    this.fetchPages();
  },
  methods: {

    navigateToCategoryManager() {
      this.$router.push('/article/categoryManager');
    },
    navigateToPageManager() {
      this.$router.push('/Theme/Page');
    },

    fetchNav() {
      api.get('/api/Nav', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        params: {
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
        }
      }).then(response => {
        const { data, total } = response.data;
        this.pagination.total = total;
        this.Nav = this.buildHierarchy(this.computeLevels(data));
        this.filteredNav = this.Nav.filter(nav => nav.nav_type === 0);
      }).catch(error => {
        console.error('获取导航列表失败', error);
        this.showAlert('error', '获取导航列表失败');
      });
    },

    fetchCategories() {
      api.get('/api/categories', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(response => {
        this.categories = this.buildHierarchy(this.computeLevels(response.data.data));
      }).catch(error => {
        console.error('获取分类列表失败', error);
        this.showAlert('error', '获取分类列表失败');
      });
    },

    fetchPages() {
      api.get('/api/postslistd', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(response => {
        this.pages = response.data.data;
      }).catch(error => {
        console.error('获取页面列表失败', error);
        this.showAlert('error', '获取页面列表失败');
      });
    },

    handlePageChange(newPage) {
      this.pagination.page = newPage;
      this.fetchNav();
    },

    showMediaLibrary() {
      this.mediaDialogVisible = true;
    },
    handleMediaSelected(media) {
      this.form.sortimg = media.filepath;
      this.mediaDialogVisible = false;
    },

    openAddDrawer() {
      this.form = {
        id: '',
        name: '',
        url: '',
        open_in_new_window: false,
        is_hidden: false,
        sort_order: 0,
        parent_id: 0,
        sortimg: '',
        nav_type: 0,
        is_system_default: 0
      };
      this.addDrawerVisible = true;
    },

    handleAddCustomNav() {
      this.form.nav_type = 0; // 自定义导航
      api.post('/api/Nav', this.form, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(() => {
        this.addDrawerVisible = false;
        this.fetchNav();
        this.showAlert('success', '添加导航成功');
      }).catch(error => {
        console.error('添加导航失败', error);
        this.showAlert('error', '添加导航失败');
      });
    },

    addCategoriesToNav() {
      this.selectedCategories.forEach(categoryId => {
        const selectedCategory = this.categories.find(cat => cat.id === categoryId);
        const data = {
          name: selectedCategory.name,
          url: `/category/${selectedCategory.slug}`,
          nav_type: 4, // 分类导航
          type_id: selectedCategory.id,
          is_system_default: 1
        };
        api.post('/api/Nav', data, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }).then(() => {
          this.fetchNav();
          this.showAlert('success', '添加分类到导航成功');
        }).catch(error => {
          console.error('添加分类到导航失败', error);
          this.showAlert('error', '添加分类到导航失败');
        });
      });
    },

    addPagesToNav() {
      this.selectedPages.forEach(pageId => {
        const selectedPage = this.pages.find(page => page.id === pageId);
        const data = {
          name: selectedPage.title,
          url: selectedPage.link ? `/page/${selectedPage.link}` : `/page/${selectedPage.id}`,
          nav_type: 5,
          type_id: selectedPage.id,
          is_system_default: 1
        };
        api.post('/api/Nav', data, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }).then(() => {
          this.fetchNav();
          this.showAlert('success', '添加页面到导航成功');
        }).catch(error => {
          console.error('添加页面到导航失败', error);
          this.showAlert('error', '添加页面到导航失败');
        });
      });
    },

    openEditDrawer(nav) {
      this.form = { ...nav };
      this.form.open_in_new_window = !!nav.open_in_new_window;
      this.form.is_hidden = !!nav.is_hidden;
      this.editDrawerVisible = true;
    },

    handleEdit() {
      api.put(`/api/Nav/${this.form.id}`, this.form, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(() => {
        this.editDrawerVisible = false;
        this.fetchNav();
        this.showAlert('success', '编辑导航成功');
      }).catch(error => {
        console.error('编辑导航失败', error);
        this.showAlert('error', '编辑导航失败');
      });
    },

    confirmDelete(id) {
      this.currentDeleteId = id;
      this.confirmDialogVisible = true;
    },

    handleDelete() {
      if (this.currentDeleteId) {
        api.delete(`/api/Nav/${this.currentDeleteId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }).then(() => {
          this.fetchNav();
          this.showAlert('success', '删除导航成功');
        }).catch(error => {
          console.error('删除导航失败', error);
          this.showAlert('error', '删除导航失败');
        }).finally(() => {
          this.confirmDialogVisible = false;
          this.currentDeleteId = null;
        });
      }
    },

    showAlert(type, message) {
      this.alert = {
        visible: true,
        type,
        message,
      };
    },

    getNavTypeLabel(navType) {
      switch (navType) {
        case 0:
          return '自定义';
        case 2:
          return '微语';
        case 3:
          return '后台管理';
        case 4:
          return '分类';
        case 5:
          return '单页';
        default:
          return '未知类型';
      }
    },

    getNavTypeStyle(navType) {
      switch (navType) {
        case 0:
          return { color: 'red' };
        case 4:
          return { color: '#626aef' };
        case 5:
          return { color: '#1cc88a' };
        default:
          return { color: 'black' };
      }
    },

    canEditUrl(navType) {
      return navType === 0;
    },

    computeLevels(items) {
      const map = {};
      const roots = [];

      items.forEach(item => {
        item.level = 0;
        map[item.id] = item;
      });

      items.forEach(item => {
        if (item.parent_id !== 0 && map[item.parent_id]) {
          const parent = map[item.parent_id];
          item.level = parent.level + 1;
        } else {
          roots.push(item);
        }
      });

      return items;
    },

    buildHierarchy(items) {
      const map = {};
      const roots = [];

      items.forEach(item => {
        map[item.id] = item;
        item.children = [];
      });

      items.forEach(item => {
        if (item.parent_id !== 0 && map[item.parent_id]) {
          map[item.parent_id].children.push(item);
        } else {
          roots.push(item);
        }
      });

      const flatten = (array) => {
        const result = [];
        array.forEach(item => {
          result.push(item);
          if (item.children.length) {
            result.push(...flatten(item.children));
          }
        });
        return result;
      };

      return flatten(roots);
    }
  },
};
</script>

<style scoped>
.drawer-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.image-preview {
  margin-top: 10px;
}
.image-preview img {
  max-width: 100%;
  height: auto;
  border: 1px solid #ddd;
  padding: 5px;
  background-color: #f9f9f9;
}

.custom-pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.el-pagination {
  background-color: #ffffff00;
  padding: 10px;
  border-radius: 5px;
}

.el-pagination .el-pager li:not(.is-active) {
  background-color: #ffffff;
}

.custom-pagination >>> .el-pager .number.is-active {
  background-color: #626aef !important;
}

.navigation-forms {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.form-section {
  width: 32%;
}

.form-section h3 {
  margin-bottom: 10px;
}

.form-section el-form {
  border: 1px solid #e5e5e5;
  padding: 20px;
  border-radius: 5px;
}

::v-deep(.custom-checkbox-allow-remark .el-checkbox__input.is-checked .el-checkbox__inner) {
  background-color: #626aef !important;
  border-color: #626aef !important;
}
::v-deep(.custom-checkbox-allow-remark .el-checkbox__input.is-checked + .el-checkbox__label) {
  color: #626aef !important;
}

.vertical-checkbox-group .el-checkbox {
  display: block;
}

.link-below-button {
  display: block;
  margin-top: 10px;
  color: #626aef;
  cursor: pointer;
}
</style>
