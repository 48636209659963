<template>
  <div class="login-container">
    <div class="login-box">
      <div class="left">
        <h2>后台登录</h2>
        <p>建站快，易上手，丰富的主题插件</p>
      </div>
      <div class="right">
        <h1>Foadmin</h1>
        <form @submit.prevent="login">
          <div class="form-item">
            <input v-model="username" placeholder="请输入账号" required />
          </div>
          <div class="form-item">
            <input v-model="password" type="password" placeholder="请输入密码" required />
          </div>
          <button type="submit" class="login-button">登录</button>
        </form>
      </div>
    </div>
    <CustomAlert v-if="message" :message="message" :type="alertType" @close="clearMessage" />
    <div class="footer">
      <p>Copyright © 2023-2024 厦门市雄笙信息科技有限公司 Foadmin V1.0.1</p>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import CustomAlert from '@/components/CustomAlert.vue';

export default {
  name: 'LoginView',
  components: {
    CustomAlert
  },
  data() {
    return {
      username: '',
      password: '',
      message: null,
      alertType: 'info' // 初始化为 info
    };
  },
  methods: {
    async login() {
      try {
        const response = await api.post('/api/UserController/login', {
          username: this.username,
          password: this.password,
        });

        if (response.data.token) {
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('userId', response.data.user.id); // 保存用户ID
          this.showMessage('登录成功，即将进入控制台...', 'success');
          setTimeout(() => {
            this.$router.push('/dashboard');
          }, 2000);
        } else {
          this.showMessage('登录失败，未收到 token', 'error');
        }
      } catch (error) {
        this.showMessage(error.response ? error.response.data.error : error.message, 'error');
      }
    },
    showMessage(message, type) {
      this.message = ''; // 重置消息
      this.$nextTick(() => {
        this.message = message;
        this.alertType = type;
      });
    },
    clearMessage() {
      this.message = null;
    }
  },
};
</script>
<style>
/* 样式保持不变 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: url('@/assets/background-image.png') no-repeat center center;
  background-size: cover;
  position: relative;
}

.login-box {
  display: flex;
  width: 800px;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.left {
  flex: 1;
  background-color: #3e6ded;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.left h2 {
  margin-bottom: 20px;
}

.left p {
  font-size: 14px !important;
  font-weight: 100 !important;
}

.footer p {
  font-size: 14px !important;
  font-weight: 100 !important;
}

.right {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right h1 {
  margin-bottom: 40px;
  font-size: 24px;
  text-align: center;
}

.form-item {
  margin-bottom: 20px;
}

input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  box-sizing: border-box;
}

.login-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #626aef;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #6b72f3;
}

.footer {
  position: absolute;
  bottom: 10px;
  text-align: center;
  color: #909399;
  font-size: 10px;
  width: 100%;
}
</style>
