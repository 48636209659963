<template>
  <router-view />
</template>


<script>
export default {
  name: 'App',
};
</script>


<style>
/* Add your styles here */

</style>
