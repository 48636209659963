<template>
  <transition name="fade">
    <div v-if="visible" :class="['alert', type]" class="alert-container">
      <span>{{ message }}</span>
      <button @click="closeAlert" class="close-button">×</button>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CustomAlert',
  props: {
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'info' // 可选值: 'success', 'error', 'warning', 'info'
    }
  },
  data() {
    return {
      visible: true
    };
  },
  watch: {
    message() {
      this.visible = true;
      this.autoClose();
    }
  },
  mounted() {
    this.autoClose();
  },
  methods: {
    closeAlert() {
      this.visible = false;
      this.$emit('close');
    },
    autoClose() {
      setTimeout(() => {
        this.closeAlert();
      }, 2000);
    }
  }
};
</script>

<style>
.alert-container {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 80%;
  width: auto; /* 自动调整宽度 */
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  opacity: 1;
  font-size: 14px;
}

.alert {
  color: #fff;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alert.info {
  background-color: #909399;
}

.alert.success {
  background-color: #0db083;
}

.alert.error {
  background-color: #f56c6c;
}

.alert.warning {
  background-color: #e6a23c;
}

.alert button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
}

.close-button {
  margin-left: auto;
  padding-left: 10px;
  cursor: pointer;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
