<template>
    <div>
      <img :src="getIconPath(media.filename)" alt="file icon" />
    </div>
  </template>
  
  <script>
  export default {
    props: ['media'],
    methods: {
      getIconPath(filename) {
        const fileType = filename.split('.').pop().toLowerCase();
        if (['zip', 'rar', '7z'].includes(fileType)) {
          return require('@/assets/icons/compressed-file.png');
        } else {
          return require('@/assets/icons/file.png');
        }
      }
    }
  };
  </script>
  
  <style scoped>
  img {
    width: 100%;
    height: 150px; /* 固定高度 */
    object-fit: cover; /* 保持图标比例并裁剪 */
  }
  </style>
  