<template>
  <div>
    <el-form :model="form" ref="form" label-width="0px">
      <el-row :gutter="20">
        <el-col :span="16" style="padding-left: 0px;">
          <el-form-item label="文章标题" prop="title" label-width="80px">
            <el-input v-model="form.title" />
          </el-form-item>
          <el-form-item label="文章内容" prop="content" label-width="80px">
            <div id="editor-wrapper">
              <div id="toolbar-container"></div>
              <div id="editor-container" class="editor"></div>
            </div>
          </el-form-item>
          <el-form-item label="摘要" prop="excerpt" label-width="80px">
            <el-checkbox v-model="form.auto_excerpt" class="custom-checkbox-allow-remark">自动截取摘要</el-checkbox>
            <el-input v-model="form.excerpt" type="textarea" style="height: 350px;" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <div class="button-group">
            <el-button color="#626aef" type="primary" @click="handleAdd">立即发布</el-button>
            <el-button color="#626aef" @click="handleSaveDraft" plain>保存草稿</el-button>
          </div>
          <el-card>
            <el-form-item prop="cover">
              <el-input v-model="form.cover" placeholder="封面图URL" />
              <div class="cover-preview">
                <img v-if="form.cover" :src="form.cover" alt="封面预览" />
                <div class="upload-button" @click="showCoverMediaLibrary">
                  <div class="upload-icon">+</div>
                </div>
              </div>
            </el-form-item>
            <el-form-item prop="category_id">
              <el-select v-model="form.category_id" placeholder="选择分类...">
                <el-option v-for="category in hierarchicalCategories" :key="category.id" :label="category.label" :value="category.id" />
              </el-select>
            </el-form-item>
            <el-form-item prop="tags">
              <el-button @click="showTagLibrary" plain class="tag-button">标签 +</el-button>
              <el-tag v-for="tag in selectedTags" :key="tag.id" closable @close="removeTag(tag)" class="custom-tag tag-margin">
                {{ tag.name }}
              </el-tag>
            </el-form-item>
            <el-form-item prop="published_at">
              <el-date-picker v-model="form.published_at" type="datetime" placeholder="发布时间" />
            </el-form-item>
            <el-form-item prop="allow_remark">
              <el-checkbox v-model="allowRemark" class="custom-checkbox-allow-remark">允许评论</el-checkbox>
              <el-checkbox v-model="top" class="custom-checkbox-top">首页置顶</el-checkbox>
              <el-checkbox v-model="sortop" class="custom-checkbox-sortop">分类置顶</el-checkbox>
            </el-form-item>
            <el-form-item>
              <el-button type="text" @click="toggleAdvancedOptions" :style="{ color: advancedOptionsVisible ? '#8b91f3' : '#626aef' }">{{ advancedOptionsVisible ? '收起' : '展开' }}高级选项</el-button>
            </el-form-item>
            <el-form-item v-show="advancedOptionsVisible">
              <el-input v-model="form.link" placeholder="链接别名" />
            </el-form-item>
            <el-form-item v-show="advancedOptionsVisible">
              <el-input v-model="form.redirect_url" placeholder="跳转链接" />
            </el-form-item>
            <el-form-item v-show="advancedOptionsVisible">
              <el-input v-model="form.password" placeholder="访问密码" />
            </el-form-item>
          </el-card>
        </el-col>
      </el-row>
    </el-form>

    <!-- Custom Alert -->
    <CustomAlert v-if="alert.visible" :type="alert.type" :message="alert.message" @close="alert.visible = false" />

    <!-- Media Library Dialog -->
    <el-dialog title="选择媒体" v-model="mediaDialogVisible">
      <media-library :selectMode="true" @media-selected="handleMediaSelected" />
    </el-dialog>

    <!-- Tag Library Dialog -->
    <el-dialog title="选择标签" v-model="tagLibraryVisible">
      <tag-library @tags-confirmed="handleTagsConfirmed" :isFromArticle="true" />
    </el-dialog>
  </div>
</template>

<script>
import { createEditor, createToolbar } from '@wangeditor/editor'
import '@wangeditor/editor/dist/css/style.css'
import CustomAlert from '@/components/CustomAlert.vue';
import MediaLibrary from '@/views/MediaLibrary.vue'; // 确保路径正确
import TagLibrary from '@/views/article/ArticleTags.vue'; // 确保路径正确
import api from '@/api';

export default {
  name: 'ArticleWrite',
  components: {
    CustomAlert,
    MediaLibrary,
    TagLibrary
  },
  data() {
    return {
      form: {
        title: '',
        content: '',
        cover: '',
        category_id: '',
        tags: [],
        published_at: '',
        allow_remark: 'y',
        top: 'n',
        sortop: 'n',
        link: '',
        redirect_url: '',
        password: '',
        excerpt: '',
        auto_excerpt: false,
        hide: false
      },
      alert: {
        visible: false,
        type: '',
        message: '',
      },
      categories: [],
      hierarchicalCategories: [],
      selectedTags: [], // 存储选中的标签
      advancedOptionsVisible: false,
      mediaDialogVisible: false,
      tagLibraryVisible: false, // 控制标签库对话框的显示
      mediaInsertFn: null // 存储插入函数
    };
  },
  created() {
    this.fetchCategories();
    this.setCurrentTime();
  },
  mounted() {
    this.initEditor();  
  },

  computed: {
    allowRemark: {
      get() {
        return this.form.allow_remark === 'y';
      },
      set(value) {
        this.form.allow_remark = value ? 'y' : 'n';
      }
    },
    top: {
      get() {
        return this.form.top === 'y';
      },
      set(value) {
        this.form.top = value ? 'y' : 'n';
      }
    },
    sortop: {
      get() {
        return this.form.sortop === 'y';
      },
      set(value) {
        this.form.sortop = value ? 'y' : 'n';
      }
    },
    computedExcerpt() {
      if (this.form.auto_excerpt && this.form.content) {
        return this.form.content.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 150);
      }
      return this.form.excerpt;
    }
  },
  methods: {
    fetchCategories() {
      api.get('/api/categories', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(response => {
        const data = response.data.data;
        const tree = this.buildCategoryTree(data);
        this.hierarchicalCategories = this.generateHierarchicalOptions(tree);
      }).catch(error => {
        console.error('获取分类列表失败', error);
        this.showAlert('error', '获取分类列表失败');
      });
    },
    buildCategoryTree(flatCategories) {
      const categoryMap = new Map();
      flatCategories.forEach(category => {
        category.children = [];
        categoryMap.set(category.id, category);
      });

      const tree = [];
      flatCategories.forEach(category => {
        if (category.parent_id && categoryMap.has(category.parent_id)) {
          const parent = categoryMap.get(category.parent_id);
          parent.children.push(category);
        } else {
          tree.push(category);
        }
      });

      return tree;
    },
    generateHierarchicalOptions(tree) {
      const buildOptions = (categories, prefix = '') => {
        let options = [];
        categories.forEach(category => {
          const label = `${prefix}${category.name}`;
          options.push({
            id: category.id,
            label: label
          });
          if (category.children && category.children.length > 0) {
            options = options.concat(buildOptions(category.children, `${prefix}│  `));
          }
        });
        return options;
      };
      return buildOptions(tree);
    },
    setCurrentTime() {
      const now = new Date();
      this.form.published_at = now;
    },
    handleAdd() {
      const payload = {
        ...this.form,
        excerpt: this.computedExcerpt, // 使用计算的摘要
        hide: 'n',
        tags: this.selectedTags.map(tag => tag.id) // 将标签ID数组传递给后端
      };
      this.submitForm(payload);
    },
    handleSaveDraft() {
      const payload = {
        ...this.form,
        excerpt: this.computedExcerpt, // 使用计算的摘要
        hide: 'y',
        tags: this.selectedTags.map(tag => tag.id) // 将标签ID数组传递给后端
      };
      this.submitForm(payload);
    },
    submitForm(payload) {
      api.post('/api/posts/add', payload, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(() => {
        this.showAlert('success', '操作成功');
        this.resetForm();
      }).catch(error => {
        console.error('操作失败', error);
        this.showAlert('error', '操作失败');
      });
    },
    showAlert(type, message) {
      this.alert = {
        visible: true,
        type,
        message,
      };
    },
    resetForm() {
      this.form = {
        title: '',
        content: '',
        cover: '',
        category_id: '',
        tags: [],
        published_at: '',
        allow_remark: 'y',
        top: 'n',
        sortop: 'n',
        link: '',
        redirect_url: '',
        password: '',
        excerpt: '',
        auto_excerpt: false,
        hide: false
      };
      this.selectedTags = []; // 重置选中的标签
      this.setCurrentTime();
    },
    initEditor() {
      const editorConfig = {
        placeholder: '开始你的创作吧...',
        MENU_CONF: {
          uploadImage: {
            customUpload: (resultFiles, insertFn) => {
              this.showMediaLibrary(insertFn, 'image');
            },
          },
          uploadVideo: {
            customUpload: (resultFiles, insertFn) => {
              this.showMediaLibrary(insertFn, 'video');
            },
          },
        },
        onChange: (editor) => {
          this.form.content = editor.getHtml();
        },
        style: {
          'white-space': 'pre-wrap'  // 确保文本内容在需要时换行
        }
      };

      const toolbarConfig = {};

      const editor = createEditor({
        selector: '#editor-container',
        config: editorConfig
      });

      const toolbar = createToolbar({
        editor,
        selector: '#toolbar-container',
        config: toolbarConfig
      });

      // Add global styles for images and videos within the editor
      const styleElement = document.createElement('style');
      styleElement.innerHTML = `
        #editor-container img, #editor-container video {
          max-width: 100%;
          height: auto;
        }
      `;
      document.head.appendChild(styleElement);

      this.editor = editor;
      this.toolbar = toolbar;
    },
    toggleAdvancedOptions() {
      this.advancedOptionsVisible = !this.advancedOptionsVisible;
    },
    showMediaLibrary(insertFn, mediaType) {
      this.mediaInsertFn = insertFn;
      this.mediaType = mediaType;
      this.mediaDialogVisible = true;
    },
    showCoverMediaLibrary() {
      this.mediaType = 'cover';
      this.mediaDialogVisible = true;
    },
    handleMediaSelected(media) {
      if (this.mediaType === 'cover') {
        this.form.cover = media.filepath;
      } else if (this.mediaType === 'image') {
        if (this.mediaInsertFn) {
          this.mediaInsertFn(media.filepath, media.alt, media.href);
        }
      } else if (this.mediaType === 'video') {
        this.mediaInsertFn(media.filepath, media.poster);
      }
      this.mediaDialogVisible = false;
    },
    showTagLibrary() {
      this.tagLibraryVisible = true;
    },
    handleTagsConfirmed(tags) {
      this.selectedTags = tags;
      this.tagLibraryVisible = false;
    },
    removeTag(tag) {
      this.selectedTags = this.selectedTags.filter(selectedTag => selectedTag.id !== tag.id);
    }
  },
  beforeUnmount() {
    if (this.editor) {
      this.editor.destroy();
      this.editor = null;
    }
    if (this.toolbar) {
      this.toolbar.destroy();
      this.toolbar = null;
    }
  }
};
</script>

<style scoped>
#editor-wrapper {
  border: 1px solid #dcdfe6;
  margin-top: 10px;
}

#toolbar-container {
  border-bottom: 1px solid #dcdfe6;
}

#editor-container {
  height: 400px;
  padding: 10px;
  word-break: break-word; /* 确保长单词在需要时换行 */
  white-space: pre-wrap;  /* 确保在内容中保留换行符，并在需要时换行 */
}

.editor {
  padding: 10px;
}

.editor img, .editor video {
  max-width: 100%;
  height: auto;
}

.el-card {
  background-color: #fff;
  padding: 20px;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
}

.cover-preview {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.cover-preview img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #dcdfe6;
}

.upload-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border: 2px dashed #dcdfe6;
  cursor: pointer;
  color: #dcdfe6;
  font-size: 32px;
}

.upload-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.tag-margin {
  margin-right: 10px;
  color: #626aef;
}

/* 自定义允许评论复选框选中颜色 */
::v-deep(.custom-checkbox-allow-remark .el-checkbox__input.is-checked .el-checkbox__inner) {
  background-color: #626aef !important;
  border-color: #626aef !important;
}
::v-deep(.custom-checkbox-allow-remark .el-checkbox__input.is-checked + .el-checkbox__label) {
  color: #626aef !important;
}

/* 自定义首页置顶复选框选中颜色 */
::v-deep(.custom-checkbox-top .el-checkbox__input.is-checked .el-checkbox__inner) {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
}
::v-deep(.custom-checkbox-top .el-checkbox__input.is-checked + .el-checkbox__label) {
  color: #ff0000 !important;
}

/* 自定义分类置顶复选框选中颜色 */
::v-deep(.custom-checkbox-sortop .el-checkbox__input.is-checked .el-checkbox__inner) {
  background-color: #1cc88a !important;
  border-color: #1cc88a !important;
}
::v-deep(.custom-checkbox-sortop .el-checkbox__input.is-checked + .el-checkbox__label) {
  color: #1cc88a !important;
}

/* 给标签选择按钮添加间隔 */
.tag-button {
  margin-right: 10px;
}

/* 自定义标签颜色 */
.custom-tag {
  background-color: #ffffff !important; /* 自定义背景色 */
  color: #626aef !important; /* 自定义文字颜色 */
  border-color: #626aef !important; /* 自定义边框颜色 */
}
</style>
