<template>
  <div>
    <el-button color="#626aef" type="primary" @click="openAddDrawer">添加产品</el-button>
    <el-table :data="products" style="width: 100%; margin-top: 20px;">
      <el-table-column prop="id" label="ID" width="50" />
      <el-table-column prop="ssl_product_id" label="产品ID" />
      <el-table-column prop="name" label="产品名称" />
      <el-table-column prop="brand" label="品牌" />
      <el-table-column prop="ssl_vendor" label="产品厂家">
        <template #default="scope">
          <span>{{ sslVendorOptions.find(option => option.value === scope.row.ssl_vendor)?.label || '无' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="ssl_logo" label="LOGO">
        <template #default="scope">
          <img :src="scope.row.ssl_logo" alt="产品LOGO" style="width: 50px; height: auto;" />
        </template>
      </el-table-column>
      <el-table-column prop="price" label="价格" />
      <el-table-column prop="ssl_type" label="证书类型" />
      <el-table-column prop="number" label="域名数量">
        <template #default="scope">
          <span>{{ domainOptions.find(option => option.value === scope.row.number)?.label || '无' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="certificate_duration" label="时长（年）" />
     
      <el-table-column label="操作">
        <template #default="scope">
          <el-button size="mini" @click="openEditDrawer(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="confirmDelete(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="custom-pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pagination.total"
        @current-change="handlePageChange"
        :page-size="pagination.pageSize"
        :current-page="pagination.page"
        style="margin-top: 20px; justify-content: center;"
      />
    </div>

    <!-- 添加产品抽屉 -->
    <el-drawer v-model="addDrawerVisible" title="添加产品" direction="rtl" size="30%">
      <template #default>
        <el-form :model="form">
          <el-form-item label="产品ID">
            <el-input v-model="form.ssl_product_id" />
          </el-form-item>
          <el-form-item label="产品名称">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item label="品牌">
            <el-input v-model="form.brand" />
          </el-form-item>
          <el-form-item label="产品厂家">
            <el-select v-model="form.ssl_vendor" placeholder="选择产品厂家">
              <el-option v-for="option in sslVendorOptions" :key="option.value" :label="option.label" :value="option.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="LOGO">
            <el-input v-model="form.ssl_logo" placeholder="LOGO URL" />
            <el-button @click="showMediaLibrary" class="image-preview">选择LOGO</el-button>
            <div v-if="form.ssl_logo" class="image-preview">
              <img :src="form.ssl_logo" alt="产品LOGO预览" />
            </div>
          </el-form-item>
          <el-form-item label="价格">
            <el-input v-model="form.price" />
          </el-form-item>
          <el-form-item label="证书类型">
            <el-input v-model="form.ssl_type" />
          </el-form-item>
          <el-form-item label="域名数量">
            <el-select v-model="form.number" placeholder="选择域名数量">
              <el-option v-for="option in domainOptions" :key="option.value" :label="option.label" :value="option.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时长（年）">
            <el-input v-model="form.certificate_duration" />
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="drawer-footer">
          <el-button color="#626aef" @click="addDrawerVisible = false" plain>取消</el-button>
          <el-button color="#626aef" type="primary" @click="handleAdd">确定</el-button>
        </div>
      </template>
    </el-drawer>

    <!-- 编辑产品抽屉 -->
    <el-drawer v-model="editDrawerVisible" title="编辑产品" direction="rtl" size="30%">
      <template #default>
        <el-form :model="form">
          <el-form-item label="产品ID">
            <el-input v-model="form.ssl_product_id" />
          </el-form-item>
          <el-form-item label="产品名称">
            <el-input v-model="form.name" />
          </el-form-item>

          <el-form-item label="品牌">
            <el-input v-model="form.brand" />
          </el-form-item>


          <el-form-item label="产品厂家">
            <el-select v-model="form.ssl_vendor" placeholder="选择产品厂家">
              <el-option v-for="option in sslVendorOptions" :key="option.value" :label="option.label" :value="option.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="LOGO">
            <el-input v-model="form.ssl_logo" placeholder="LOGO URL" />
            <el-button @click="showMediaLibrary" class="image-preview">选择LOGO</el-button>
            <div v-if="form.ssl_logo" class="image-preview">
              <img :src="form.ssl_logo" alt="产品LOGO预览" />
            </div>
          </el-form-item>
          <el-form-item label="价格">
            <el-input v-model="form.price" />
          </el-form-item>
          <el-form-item label="证书类型">
            <el-input v-model="form.ssl_type" />
          </el-form-item>
          <el-form-item label="域名数量">
            <el-select v-model="form.number" placeholder="选择域名数量">
              <el-option v-for="option in domainOptions" :key="option.value" :label="option.label" :value="option.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时长（年）">
            <el-input v-model="form.certificate_duration" />
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="drawer-footer">
          <el-button color="#626aef" @click="editDrawerVisible = false" plain>取消</el-button>
          <el-button color="#626aef" type="primary" @click="handleEdit">确定</el-button>
        </div>
      </template>
    </el-drawer>

    <!-- 媒体库对话框 -->
    <el-dialog title="选择媒体" v-model="mediaDialogVisible">
      <media-library :selectMode="true" @media-selected="handleMediaSelected" />
    </el-dialog>

    <!-- Custom Alert -->
    <CustomAlert v-if="alert.visible" :type="alert.type" :message="alert.message" @close="alert.visible = false" />

    <!-- Confirm Dialog -->
    <ConfirmDialog
      v-model:visible="confirmDialogVisible"
      message="确定要删除这个产品吗？"
      @confirm="handleDelete"
      @cancel="confirmDialogVisible = false"
    />
  </div>
</template>

<script>
import api from '@/api'; // 引入已配置好的api实例
import CustomAlert from '@/components/CustomAlert.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import MediaLibrary from '@/views/MediaLibrary.vue'; // 确保路径正确

export default {
  name: 'ProductManager',
  components: {
    CustomAlert,
    ConfirmDialog,
    MediaLibrary
  },
  data() {
    return {
      products: [],
      form: {
        id: '',
        ssl_product_id: '',
        name: '',
        brand: '',
        ssl_vendor: '',
        ssl_logo: '',
        price: '',
        ssl_type: '',
        number: '',
        certificate_duration: ''
      },
      addDrawerVisible: false,
      editDrawerVisible: false,
      mediaDialogVisible: false,
      confirmDialogVisible: false,
      currentDeleteId: null,
      alert: {
        visible: false,
        type: '',
        message: '',
      },
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      sslVendorOptions: [
        { value: 1, label: 'Certbase' },
        { value: 2, label: '锐成' }
      ],
      domainOptions: [
        { value: 1, label: '单域名' },
        { value: 2, label: '通配符' }
      ],
    };
  },
  created() {
    this.fetchProducts();
  },
  methods: {
    fetchProducts() {
      api.get('/api/products', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        params: {
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
        }
      }).then(response => {
        const { data, total } = response.data;
        this.pagination.total = total;
        this.products = data;
      }).catch(error => {
        console.error('获取产品列表失败', error);
        this.showAlert('error', '获取产品列表失败');
      });
    },

    handlePageChange(newPage) {
      this.pagination.page = newPage;
      this.fetchProducts();
    },

    showMediaLibrary() {
      this.mediaDialogVisible = true;
    },
    handleMediaSelected(media) {
      this.form.ssl_logo = media.filepath;
      this.mediaDialogVisible = false;
    },

    openAddDrawer() {
      this.form = {
        id: '',
        ssl_product_id: '',
        name: '',
        brand: '',
        ssl_vendor: '',
        ssl_logo: '',
        price: '',
        ssl_type: '',
        number: '',
        certificate_duration: ''
      };
      this.addDrawerVisible = true;
    },

    handleAdd() {
      api.post('/api/products', this.form, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(() => {
        this.addDrawerVisible = false;
        this.fetchProducts();
        this.showAlert('success', '添加产品成功');
      }).catch(error => {
        console.error('添加产品失败', error);
        this.showAlert('error', '添加产品失败');
      });
    },

    openEditDrawer(product) {
      this.form = { ...product };
      this.editDrawerVisible = true;
    },

    handleEdit() {
      api.put(`/api/products/${this.form.id}`, this.form, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(() => {
        this.editDrawerVisible = false;
        this.fetchProducts();
        this.showAlert('success', '编辑产品成功');
      }).catch(error => {
        console.error('编辑产品失败', error);
        this.showAlert('error', '编辑产品失败');
      });
    },

    confirmDelete(id) {
      this.currentDeleteId = id;
      this.confirmDialogVisible = true;
    },

    handleDelete() {
      if (this.currentDeleteId) {
        api.delete(`/api/products/${this.currentDeleteId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }).then(() => {
          this.fetchProducts();
          this.showAlert('success', '删除产品成功');
        }).catch(error => {
          console.error('删除产品失败', error);
          this.showAlert('error', '删除产品失败');
        }).finally(() => {
          this.confirmDialogVisible = false;
          this.currentDeleteId = null;
        });
      }
    },

    showAlert(type, message) {
      this.alert = {
        visible: true,
        type,
        message,
      };
    },
  },
};
</script>

<style scoped>
.drawer-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.image-preview {
  margin-top: 10px;
}
.image-preview img {
  max-width: 100%;
  height: auto;
  border: 1px solid #ddd;
  padding: 5px;
  background-color: #f9f9f9;
}

.custom-pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.el-pagination {
  background-color: #ffffff00; /* 自定义背景色 */
  padding: 10px;
  border-radius: 5px;
}

.el-pagination .el-pager li:not(.is-active) {
  background-color: #ffffff; /* 设置非激活状态分页项的背景色 */
}

.custom-pagination >>> .el-pager .number.is-active {
  background-color: #626aef !important; /* 使用 !important 确保样式优先级 */
}
</style>
