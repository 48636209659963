<template>
  <div>
    <!-- 添加和批量删除按钮 -->
    <el-button color="#626aef" type="primary" @click="openAddDrawer">添加角色</el-button>
    <el-button color="#ff4d4f" type="danger" @click="confirmBatchDelete" :disabled="!selectedRoles.length">批量删除</el-button>
    
    <!-- 角色列表 -->
    <el-table :data="categories" style="width: 100%; margin-top: 20px;" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="ID" width="50" />
      <el-table-column label="角色名称">
        <template #default="scope">
          <span :style="{ paddingLeft: `${scope.row.level * 20}px` }">
            {{ scope.row.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="description" label="描述" />
      <el-table-column label="父级角色">
        <template #default="scope">
          <span>{{ scope.row.parent ? scope.row.parent.name : '无' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间" />
      <el-table-column prop="updated_at" label="更新时间" />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button size="mini" @click="openEditDrawer(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="confirmDelete(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    
    <!-- 分页 -->
    <div class="custom-pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pagination.total"
        @current-change="handlePageChange"
        :page-size="pagination.pageSize"
        :current-page="pagination.page"
        style="margin-top: 20px; justify-content: center;"
      />
    </div>

    <!-- 添加角色抽屉 -->
    <el-drawer v-model="addDrawerVisible" title="添加角色" direction="rtl" size="30%">
      <template #default>
        <el-form :model="form">
          <el-form-item label="角色名称">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item label="角色描述">
            <el-input v-model="form.description" />
          </el-form-item>
          <el-form-item label="父级角色">
            <el-select v-model="form.parent_id" placeholder="选择父级角色">
              <el-option v-for="category in parentCategories" :key="category.id" :label="category.nameWithPrefix" :value="category.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="权限">
            <el-select v-model="form.permissions" multiple placeholder="选择权限">
              <el-option v-for="permission in permissions" :key="permission.id" :label="permission.name" :value="permission.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="drawer-footer">
          <el-button color="#626aef" @click="addDrawerVisible = false" plain>取消</el-button>
          <el-button color="#626aef" type="primary" @click="handleAdd">确定</el-button>
        </div>
      </template>
    </el-drawer>

    <!-- 编辑角色抽屉 -->
    <el-drawer v-model="editDrawerVisible" title="编辑角色" direction="rtl" size="30%">
      <template #default>
        <el-form :model="form">
          <el-form-item label="角色名称">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item label="角色描述">
            <el-input v-model="form.description" />
          </el-form-item>
          <el-form-item label="父级角色">
            <el-select v-model="form.parent_id" placeholder="选择父级角色">
              <el-option v-for="category in parentCategories" :key="category.id" :label="category.nameWithPrefix" :value="category.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="权限">
            <el-select v-model="form.permissions" multiple placeholder="选择权限">
              <el-option v-for="permission in permissions" :key="permission.id" :label="permission.name" :value="permission.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="drawer-footer">
          <el-button color="#626aef" @click="editDrawerVisible = false" plain>取消</el-button>
          <el-button color="#626aef" type="primary" @click="handleEdit">确定</el-button>
        </div>
      </template>
    </el-drawer>

    <!-- Custom Alert -->
    <CustomAlert v-if="alert.visible" :type="alert.type" :message="alert.message" @close="alert.visible = false" />

    <!-- Confirm Dialog -->
    <ConfirmDialog
      v-model:visible="confirmDialogVisible"
      message="确定要删除这个角色吗？"
      @confirm="handleDelete"
      @cancel="confirmDialogVisible = false"
    />
    
    <!-- Batch Confirm Dialog -->
    <ConfirmDialog
      v-model:visible="confirmBatchDialogVisible"
      message="确定要删除选中的角色吗？"
      @confirm="handleBatchDelete"
      @cancel="confirmBatchDialogVisible = false"
    />
  </div>
</template>

<script>
import api from '@/api';
import CustomAlert from '@/components/CustomAlert.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
  name: 'RolesManager',
  components: {
    CustomAlert,
    ConfirmDialog
  },
  data() {
    return {
      categories: [],
      parentCategories: [],
      selectedRoles: [],
      form: {
        id: '',
        name: '',
        description: '',
        parent_id: 0,
        permissions: [] // 添加 permissions 字段
      },
      addDrawerVisible: false,
      editDrawerVisible: false,
      confirmDialogVisible: false,
      confirmBatchDialogVisible: false,
      currentDeleteId: null,
      alert: {
        visible: false,
        type: '',
        message: '',
      },
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  created() {
    this.fetchCategories();
    this.fetchPermissions();
  },
  methods: {
    fetchPermissions() {
      api.get('/api/permissions', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(response => {
        this.permissions = response.data.data;
      }).catch(error => {
        console.error('获取权限列表失败', error);
        this.showAlert('error', '获取权限列表失败');
      });
    },
    fetchCategories() {
      api.get('/api/roles', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        params: {
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
        }
      }).then(response => {
        const { data, total } = response.data;
        this.categories = this.buildCategoryTree(data);
        this.pagination.total = total;
        this.parentCategories = this.buildParentCategories(data);
      }).catch(error => {
        console.error('获取角色列表失败', error);
        this.showAlert('error', '获取角色列表失败');
      });
    },
    fetchRole(id) {
      api.get(`/api/roles/${id}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(response => {
        const role = response.data.data;
        this.form = {
          id: role.id,
          name: role.name,
          description: role.description,
          parent_id: role.parent_id,
          permissions: role.permissions.map(p => p.id) // 提取权限ID数组
        };
        this.editDrawerVisible = true;
      }).catch(error => {
        console.error('获取角色详情失败', error);
        this.showAlert('error', '获取角色详情失败');
      });
    },
    buildParentCategories(flatCategories) {
      const buildHierarchy = (categories, parentId = 0, prefix = '') => {
        let result = [];
        categories.forEach(category => {
          if (category.parent_id === parentId) {
            category.nameWithPrefix = prefix + category.name;
            result.push(category);
            const children = buildHierarchy(categories, category.id, prefix + '│  ');
            result = result.concat(children);
          }
        });
        return result;
      };

      return buildHierarchy(flatCategories);
    },
    handlePageChange(newPage) {
      this.pagination.page = newPage;
      this.fetchCategories();
    },
    buildCategoryTree(flatCategories) {
      const categoryMap = new Map();
      flatCategories.forEach(category => {
        category.children = [];
        categoryMap.set(category.id, category);
      });

      const tree = [];
      flatCategories.forEach(category => {
        if (category.parent_id) {
          const parent = categoryMap.get(category.parent_id);
          if (parent) {
            parent.children.push(category);
          }
        } else {
          tree.push(category);
        }
      });

      return this.flattenTree(tree);
    },
    flattenTree(tree, level = 0) {
      let flatList = [];
      tree.forEach(node => {
        node.level = level;
        flatList.push(node);
        if (node.children.length) {
          flatList = flatList.concat(this.flattenTree(node.children, level + 1));
        }
      });
      return flatList;
    },
    openAddDrawer() {
      this.form = {
        id: '',
        name: '',
        description: '',
        parent_id: 0,
        permissions: [] // 添加 permissions 字段
      };
      this.addDrawerVisible = true;
    },
    handleAdd() {
      api.post('/api/roles', this.form, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(() => {
        this.addDrawerVisible = false;
        this.fetchCategories();
        this.showAlert('success', '添加角色成功');
      }).catch(error => {
        console.error('添加角色失败', error);
        this.showAlert('error', '添加角色失败');
      });
    },
    openEditDrawer(category) {
      this.fetchRole(category.id);
    },
    handleEdit() {
      api.put(`/api/roles/${this.form.id}`, this.form, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(() => {
        this.editDrawerVisible = false;
        this.fetchCategories();
        this.showAlert('success', '编辑角色成功');
      }).catch(error => {
        console.error('编辑角色失败', error);
        this.showAlert('error', '编辑角色失败');
      });
    },
    confirmDelete(id) {
      this.currentDeleteId = id;
      this.confirmDialogVisible = true;
    },
    handleDelete() {
      if (this.currentDeleteId) {
        api.delete(`/api/roles/${this.currentDeleteId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }).then(() => {
          this.fetchCategories();
          this.showAlert('success', '删除角色成功');
        }).catch(error => {
          console.error('删除角色失败', error);
          this.showAlert('error', '删除角色失败');
        }).finally(() => {
          this.confirmDialogVisible = false;
          this.currentDeleteId = null;
        });
      }
    },
    confirmBatchDelete() {
      if (this.selectedRoles.length === 0) {
        this.showAlert('error', '请选择要删除的角色');
        return;
      }
      this.confirmBatchDialogVisible = true;
    },
    handleBatchDelete() {
      const ids = this.selectedRoles.map(role => role.id);
      api.post('/api/rolest/batch-delete', { ids }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(() => {
        this.fetchCategories();
        this.showAlert('success', '批量删除成功');
      }).catch(error => {
        console.error('批量删除失败', error);
        this.showAlert('error', '批量删除失败');
      }).finally(() => {
        this.confirmBatchDialogVisible = false;
      });
    },
    handleSelectionChange(selection) {
      this.selectedRoles = selection;
    },
    showAlert(type, message) {
      this.alert = {
        visible: true,
        type,
        message,
      };
    },
  },
};
</script>

<style scoped>
.drawer-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.custom-pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.el-pagination {
  background-color: #ffffff00;
  padding: 10px;
  border-radius: 5px;
}

.el-pagination .el-pager li:not(.is-active) {
  background-color: #ffffff;
}

.custom-pagination >>> .el-pager .number.is-active {
  background-color: #626aef !important;
}
</style>
