<template>
  <div>
    <el-button color="#626aef" type="primary" @click="openAddDrawer">添加标签</el-button>
    <!-- 确认选择按钮 -->
   <el-button color="#626aef" type="primary" @click="confirmSelection" plain v-if="isFromArticle" :disabled="multipleSelection.length === 0">确认选择</el-button>
    <el-button type="danger" @click="confirmBatchDelete" :disabled="multipleSelection.length === 0">批量删除</el-button>
    <div class="table-wrapper">
      <el-table
        ref="multipleTable"
        :data="tags"
        style="width: 100%; margin-top: 20px;"
        @selection-change="handleSelectionChange"
        @row-click="selectTag"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="ID" width="50" />
        <el-table-column prop="name" label="标签名称" />
        <el-table-column prop="slug" label="标签别名" />
        <el-table-column prop="created_at" label="创建时间" />
        <el-table-column prop="updated_at" label="更新时间" />
        <el-table-column label="操作">
          <template #default="scope">
            <div class="action-buttons">
              <el-button size="mini" @click="openEditDrawer(scope.row)">编辑</el-button>
              <el-button size="mini" type="danger" @click="confirmDelete(scope.row.id)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="custom-pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pagination.total"
        @current-change="handlePageChange"
        :page-size="pagination.pageSize"
        :current-page="pagination.page"
        style="margin-top: 20px; justify-content: center;"
      />
    </div>
    <!-- 添加标签抽屉 -->
    <el-drawer v-model="addDrawerVisible" title="添加标签" direction="rtl" size="30%">
      <template #default>
        <el-form :model="form">
          <el-form-item label="标签名称">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item label="标签别名">
            <el-input v-model="form.slug" />
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="drawer-footer">
          <el-button color="#626aef" @click="addDrawerVisible = false" plain>取消</el-button>
          <el-button color="#626aef" type="primary" @click="handleAdd">确定</el-button>
        </div>
      </template>
    </el-drawer>

    <!-- 编辑标签抽屉 -->
    <el-drawer v-model="editDrawerVisible" title="编辑标签" direction="rtl" size="30%">
      <template #default>
        <el-form :model="form">
          <el-form-item label="标签名称">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item label="标签别名">
            <el-input v-model="form.slug" />
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="drawer-footer">
          <el-button color="#626aef" @click="editDrawerVisible = false" plain>取消</el-button>
          <el-button color="#626aef" type="primary" @click="handleEdit">确定</el-button>
        </div>
      </template>
    </el-drawer>

    <!-- Custom Alert -->
    <CustomAlert v-if="alert.visible" :type="alert.type" :message="alert.message" @close="alert.visible = false" />

    <!-- Confirm Dialog -->
    <ConfirmDialog
      v-model:visible="confirmDialogVisible"
      message="确定要删除这个标签吗？"
      @confirm="handleDelete"
      @cancel="confirmDialogVisible = false"
    />

    <!-- Confirm Batch Delete Dialog -->
    <ConfirmDialog
      v-model:visible="confirmBatchDialogVisible"
      message="确定要删除选中的标签吗？"
      @confirm="handleBatchDelete"
      @cancel="confirmBatchDialogVisible = false"
    />

 
  </div>
</template>

<script>
import api from '@/api'; // 引入已配置好的api实例
import CustomAlert from '@/components/CustomAlert.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
  name: 'TagLibrary',
  components: {
    CustomAlert,
    ConfirmDialog
  },
  props: {
    isFromArticle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tags: [],
      form: {
        id: '',
        name: '',
        slug: ''
      },
      originalForm: {},
      addDrawerVisible: false,
      editDrawerVisible: false,
      confirmDialogVisible: false,
      confirmBatchDialogVisible: false,
      currentDeleteId: null,
      multipleSelection: [],
      alert: {
        visible: false,
        type: '',
        message: '',
      },
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      }
    };
  },
  created() {
    this.fetchTags();
  },
  methods: {
    fetchTags() {
      api.get('/api/postags', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        params: {
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
        }
      }).then(response => {
        const { data, total } = response.data;
        this.tags = data;
        this.pagination.total = total;
      }).catch(error => {
        console.error('获取标签列表失败', error);
        this.showAlert('error', '获取标签列表失败');
      });
    },
    handlePageChange(newPage) {
      this.pagination.page = newPage;
      this.fetchTags();
    },
    openAddDrawer() {
      this.form = {
        id: '',
        name: '',
        slug: ''
      };
      this.addDrawerVisible = true;
    },
    handleAdd() {
      // 处理slug为空的情况
      if (!this.form.slug) {
        delete this.form.slug;
      }
      api.post('/api/postags', this.form, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(() => {
        this.addDrawerVisible = false;
        this.fetchTags();
        this.showAlert('success', '添加标签成功');
      }).catch(error => {
        console.error('添加标签失败', error);
        this.showAlert('error', '添加标签失败');
      });
    },
    openEditDrawer(tag) {
      this.form = { ...tag };
      this.originalForm = { ...tag }; // 保存原始表单数据
      this.editDrawerVisible = true;
    },
    handleEdit() {
      const payload = {};
      for (const key in this.form) {
        if (this.form[key] !== this.originalForm[key]) {
          payload[key] = this.form[key];
        }
      }

      api.put(`/api/postags/${this.form.id}`, payload, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(() => {
        this.editDrawerVisible = false;
        this.fetchTags();
        this.showAlert('success', '编辑标签成功');
      }).catch(error => {
        console.error('编辑标签失败', error);
        this.showAlert('error', '编辑标签失败');
      });
    },
    confirmDelete(id) {
      this.currentDeleteId = id;
      this.confirmDialogVisible = true;
    },
    handleDelete() {
      if (this.currentDeleteId) {
        api.delete(`/api/postags/${this.currentDeleteId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }).then(() => {
          this.fetchTags();
          this.showAlert('success', '删除标签成功');
        }).catch(error => {
          console.error('删除标签失败', error);
          this.showAlert('error', '删除标签失败');
        }).finally(() => {
          this.confirmDialogVisible = false;
          this.currentDeleteId = null;
        });
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    confirmBatchDelete() {
      this.confirmBatchDialogVisible = true;
    },
    handleBatchDelete() {
      const ids = this.multipleSelection.map(item => item.id);
      api.post('/api/postagst/adminbatch-delete', { ids }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(() => {
        this.fetchTags();
        this.showAlert('success', '批量删除成功');
      }).catch(error => {
        console.error('批量删除失败', error);
        this.showAlert('error', '批量删除失败');
      }).finally(() => {
        this.confirmBatchDialogVisible = false;
        this.multipleSelection = [];
      });
    },
    showAlert(type, message) {
      this.alert = {
        visible: true,
        type,
        message,
      };
    },
    selectTag(tag) {
      if (this.isFromArticle) {
        this.$emit('tag-selected', tag);
      }
    },
    confirmSelection() {
      const selectedTags = this.multipleSelection.map(tag => ({
        id: tag.id,
        name: tag.name
      }));
      this.$emit('tags-confirmed', selectedTags);
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.table-wrapper {
  overflow-x: auto;
  width: 100%;
}

.drawer-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.custom-pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.el-pagination {
  background-color: #ffffff00;
  padding: 10px;
  border-radius: 5px;
}

.el-pagination .el-pager li:not(.is-active) {
  background-color: #ffffff;
}

.custom-pagination >>> .el-pager .number.is-active {
  background-color: #626aef !important;
}
</style>
