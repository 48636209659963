<template>
  <div>
    <el-button color="#626aef" type="primary" @click="openAddDrawer">添加权限</el-button>
    <el-button type="danger" @click="confirmBatchDelete" :disabled="!selectedRoles.length">批量删除</el-button>
    <el-table :data="visibleCategories" style="width: 100%; margin-top: 20px;" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="ID" width="50"></el-table-column>
      <el-table-column label="权限名称">
        <template #default="scope">
          <span :style="{ paddingLeft: `${scope.row.level * 20}px` }">
            <el-icon v-if="scope.row.children && scope.row.children.length" @click.stop="toggleExpand(scope.row)">
              <ArrowRight v-if="!scope.row.expanded" />
              <ArrowDown v-else />
            </el-icon>
            {{ scope.row.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="description" label="描述"></el-table-column>
      <el-table-column label="权限规则">
        <template #default="scope">
          <span>{{ scope.row.rule }}</span>
        </template>
      </el-table-column>
      <el-table-column label="父级权限">
        <template #default="scope">
          <span>{{ scope.row.parent ? scope.row.parent.name : '无' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间"></el-table-column>
      <el-table-column prop="updated_at" label="更新时间"></el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button size="mini" @click="openEditDrawer(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="confirmDelete(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="custom-pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pagination.total"
        @current-change="handlePageChange"
        :page-size="pagination.pageSize"
        :current-page="pagination.page"
        style="margin-top: 20px; justify-content: center;"
      />
    </div>

    <!-- 添加权限抽屉 -->
    <el-drawer v-model="addDrawerVisible" title="添加权限" direction="rtl" size="30%">
      <template #default>
        <el-form :model="form">
          <el-form-item label="权限名称">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="权限描述">
            <el-input v-model="form.description"></el-input>
          </el-form-item>
          <el-form-item label="权限规则">
            <el-input v-model="form.rule"></el-input>
          </el-form-item>
          <el-form-item label="父级权限">
            <el-select v-model="form.parent_id" placeholder="选择父级权限">
              <el-option v-for="category in parentCategories" :key="category.id" :label="category.nameWithPrefix" :value="category.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="drawer-footer">
          <el-button color="#626aef" @click="addDrawerVisible = false" plain>取消</el-button>
          <el-button color="#626aef" type="primary" @click="handleAdd">确定</el-button>
        </div>
      </template>
    </el-drawer>

    <!-- 编辑权限抽屉 -->
    <el-drawer v-model="editDrawerVisible" title="编辑权限" direction="rtl" size="30%">
      <template #default>
        <el-form :model="form">
          <el-form-item label="权限名称">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="权限描述">
            <el-input v-model="form.description"></el-input>
          </el-form-item>
          <el-form-item label="权限规则">
            <el-input v-model="form.rule"></el-input>
          </el-form-item>
          <el-form-item label="父级权限">
            <el-select v-model="form.parent_id" placeholder="选择父级权限">
              <el-option v-for="category in parentCategories" :key="category.id" :label="category.nameWithPrefix" :value="category.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="drawer-footer">
          <el-button color="#626aef" @click="editDrawerVisible = false" plain>取消</el-button>
          <el-button color="#626aef" type="primary" @click="handleEdit">确定</el-button>
        </div>
      </template>
    </el-drawer>

    <!-- Custom Alert -->
    <CustomAlert v-if="alert.visible" :type="alert.type" :message="alert.message" @close="alert.visible = false" />

    <!-- Confirm Dialog -->
    <ConfirmDialog
      v-model:visible="confirmDialogVisible"
      message="确定要删除这个权限吗？"
      @confirm="handleDelete"
      @cancel="confirmDialogVisible = false"
    />
    
    <!-- Batch Confirm Dialog -->
    <ConfirmDialog
      v-model:visible="confirmBatchDialogVisible"
      message="确定要删除选中的权限吗？"
      @confirm="handleBatchDelete"
      @cancel="confirmBatchDialogVisible = false"
    />
  </div>
</template>

<script>
import api from '@/api';
import CustomAlert from '@/components/CustomAlert.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { ArrowRight, ArrowDown } from '@element-plus/icons-vue';

export default {
  name: 'RuleRoles',
  components: {
    CustomAlert,
    ConfirmDialog,
    ArrowRight,
    ArrowDown
  },
  data() {
    return {
      categories: [],
      parentCategories: [],
      selectedRoles: [],
      form: {
        id: '',
        name: '',
        description: '',
        rule: '',
        parent_id: 0
      },
      addDrawerVisible: false,
      editDrawerVisible: false,
      confirmDialogVisible: false,
      confirmBatchDialogVisible: false,
      currentDeleteId: null,
      alert: {
        visible: false,
        type: '',
        message: '',
      },
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  computed: {
    visibleCategories() {
      return this.categories.filter(category => !category.parent_id || this.isParentExpanded(category));
    }
  },
  created() {
    this.fetchCategories();
  },
  methods: {
    fetchCategories() {
      api.get('/api/RuleRoles', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        params: {
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
        }
      }).then(response => {
        const { data, total } = response.data;
        this.categories = this.buildCategoryTree(data);
        this.pagination.total = total;
        this.parentCategories = this.buildParentCategories(data);
      }).catch(error => {
        console.error('获取权限列表失败', error);
        this.showAlert('error', '获取权限列表失败');
      });
    },

    buildParentCategories(flatCategories) {
      const buildHierarchy = (categories, parentId = 0, prefix = '') => {
        let result = [];
        categories.forEach(category => {
          if (category.parent_id === parentId) {
            category.nameWithPrefix = prefix + category.name;
            result.push(category);
            const children = buildHierarchy(categories, category.id, prefix + '│  ');
            result = result.concat(children);
          }
        });
        return result;
      };

      return buildHierarchy(flatCategories);
    },

    handlePageChange(newPage) {
      this.pagination.page = newPage;
      this.fetchCategories();
    },

    buildCategoryTree(flatCategories) {
      const categoryMap = new Map();
      flatCategories.forEach(category => {
        category.children = [];
        category.expanded = false;
        categoryMap.set(category.id, category);
      });

      const tree = [];
      flatCategories.forEach(category => {
        if (category.parent_id) {
          const parent = categoryMap.get(category.parent_id);
          if (parent) {
            parent.children.push(category);
          }
        } else {
          tree.push(category);
        }
      });

      return this.flattenTree(tree);
    },

    flattenTree(tree, level = 0) {
      let flatList = [];
      tree.forEach(node => {
        node.level = level;
        flatList.push(node);
        if (node.children.length) {
          flatList = flatList.concat(this.flattenTree(node.children, level + 1));
        }
      });
      return flatList;
    },

    toggleExpand(category) {
      category.expanded = !category.expanded;
    },

    isParentExpanded(category) {
      let parent = this.categories.find(cat => cat.id === category.parent_id);
      while (parent) {
        if (!parent.expanded) {
          return false;
        }
        parent = this.categories.find(cat => cat.id === parent.parent_id);
      }
      return true;
    },

    openAddDrawer() {
      this.form = {
        id: '',
        name: '',
        description: '',
        rule: '',
        parent_id: 0
      };
      this.addDrawerVisible = true;
    },

    handleAdd() {
      api.post('/api/RuleRoles', this.form, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(() => {
        this.addDrawerVisible = false;
        this.fetchCategories();
        this.showAlert('success', '添加权限成功');
      }).catch(error => {
        console.error('添加权限失败', error);
        this.showAlert('error', '添加权限失败');
      });
    },

    openEditDrawer(category) {
      this.form = { ...category };
      this.editDrawerVisible = true;
    },

    handleEdit() {
      api.put(`/api/RuleRoles/${this.form.id}`, this.form, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(() => {
        this.editDrawerVisible = false;
        this.fetchCategories();
        this.showAlert('success', '编辑权限成功');
      }).catch(error => {
        console.error('编辑权限失败', error);
        this.showAlert('error', '编辑权限失败');
      });
    },

    confirmDelete(id) {
      this.currentDeleteId = id;
      this.confirmDialogVisible = true;
    },

    handleDelete() {
      if (this.currentDeleteId) {
        api.delete(`/api/RuleRoles/${this.currentDeleteId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }).then(() => {
          this.fetchCategories();
          this.showAlert('success', '删除权限成功');
        }).catch(error => {
          console.error('删除权限失败', error);
          this.showAlert('error', '删除权限失败');
        }).finally(() => {
          this.confirmDialogVisible = false;
          this.currentDeleteId = null;
        });
      }
    },

    confirmBatchDelete() {
      if (this.selectedRoles.length === 0) {
        this.showAlert('error', '请选择要删除的权限');
        return;
      }
      this.confirmBatchDialogVisible = true;
    },

    handleBatchDelete() {
      const ids = this.selectedRoles.map(role => role.id);
      api.post('/api/RuleRolest/batchdeletet', { ids }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(() => {
        this.fetchCategories();
        this.showAlert('success', '批量删除成功');
      }).catch(error => {
        console.error('批量删除失败', error);
        this.showAlert('error', '批量删除失败');
      }).finally(() => {
        this.confirmBatchDialogVisible = false;
      });
    },

    handleSelectionChange(selection) {
      this.selectedRoles = selection;
    },

    showAlert(type, message) {
      this.alert = {
        visible: true,
        type,
        message,
      };
    },
  },
};
</script>

<style scoped>
.drawer-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.custom-pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.el-pagination {
  background-color: #ffffff00;
  padding: 10px;
  border-radius: 5px;
}

.el-pagination .el-pager li:not(.is-active) {
  background-color: #ffffff;
}

.custom-pagination >>> .el-pager .number.is-active {
  background-color: #626aef !important;
}
</style>
