<template>
  <div class="layout">
    <SidebarNav />
    <div class="main-content">
      <TopNav />
      <div class="content">
        <slot></slot> <!-- 插槽用于嵌入子组件 -->

     </div>
    </div>
  </div>
</template>

<script>
import SidebarNav from '@/components/SidebarNav.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'CommonLayout',
  components: {
    SidebarNav,
    TopNav,
  },
};
</script>

<style scoped>
.layout {
  display: flex;
  height: 100vh;
}

.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  padding: 20px;
  background-color: #f4f4f4;
  overflow-y: auto; /* 使内容部分可以滚动 */
  height: calc(100vh - 50px); /* 减去 TopNav 高度 */
}

</style>
