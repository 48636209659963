<template>
  <div>
    
    <div v-if="dashboardData">
      <p>这里是仪表盘</p>
      <p>Foadmin框架</p>
      <!-- 其他数据展示 -->
    </div>
  </div>
</template>

<script>
import api from '@/api';

export default {
  name: 'DashboardView',
  data() {
    return {
      dashboardData: null,
    };
  },
  async created() {
    try {
      const response = await api.get('/apiadmin/StatsController/dashboard');
      this.dashboardData = response.data;
    } catch (error) {
      alert('获取仪表盘数据失败');
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('token');
      this.$router.push('/login');
    }
  }
};
</script>

