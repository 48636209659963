<template>
  <div>
    <div class="top-bar">
      <div class="left-buttons">
        <el-button color="#626aef" type="primary" @click="navigateToWriteArticle">
          添加单页
        </el-button>
        <el-button
          type="danger"
          @click="confirmBatchDelete"
          :disabled="multipleSelection.length === 0"
        >
          批量删除
        </el-button>
      </div>
      <div class="search-bar">
        <el-input
          v-model="search.title"
          placeholder="搜索标题"
          @keyup.enter="fetchPosts"
          style="width: 200px; margin-right: 10px;"
        />
        <el-select
          v-model="search.category_id"
          placeholder="选择分类"
          @change="fetchPosts"
          style="width: 150px; margin-right: 10px;"
        >
          <el-option
            v-for="category in formattedCategories"
            :key="category.id"
            :label="category.label"
            :value="category.id"
          />
        </el-select>
        <el-input
          v-model="search.author_nickname"
          placeholder="输入作者昵称"
          @keyup.enter="fetchPosts"
          style="width: 200px; margin-right: 10px;"
        />
        <el-button color="#626aef" type="primary" @click="fetchPosts">搜索</el-button>
      </div>
    </div>
    <el-button
      color="#626aef"
      type="primary"
      @click="confirmSelection"
      plain
      v-if="isFromArticle"
      :disabled="multipleSelection.length === 0"
    >
      确认选择
    </el-button>
    <div class="table-wrapper">
      <el-table
        ref="multipleTable"
        :data="posts"
        style="width: 100%; margin-top: 20px;"
        @selection-change="handleSelectionChange"
        @row-click="selectPost"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="ID" width="50" />
        <el-table-column prop="title" label="标题" />
        <el-table-column prop="category_name" label="分类名称" />
        <el-table-column prop="author_nickname" label="作者昵称" />
        <el-table-column prop="views" label="阅读量" />
        <el-table-column prop="comnum" label="评论数量" />
        <el-table-column prop="updated_at" label="更新时间" />
        <el-table-column label="操作">
          <template #default="scope">
            <div class="action-buttons">
              <el-button size="mini" @click="navigateToEditArticle(scope.row.id)">
                编辑
              </el-button>
              <el-button size="mini" type="danger" @click="confirmDelete(scope.row.id)">
                删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="custom-pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pagination.total"
        @current-change="handlePageChange"
        :page-size="pagination.pageSize"
        :current-page="pagination.page"
        style="margin-top: 20px; justify-content: center;"
      />
    </div>

    <!-- Custom Alert -->
    <CustomAlert
      v-if="alert.visible"
      :type="alert.type"
      :message="alert.message"
      @close="alert.visible = false"
    />

    <!-- Confirm Dialog -->
    <ConfirmDialog
      v-model:visible="confirmDialogVisible"
      message="确定要删除这篇单页吗？"
      @confirm="handleDelete"
      @cancel="confirmDialogVisible = false"
    />

    <!-- Confirm Batch Delete Dialog -->
    <ConfirmDialog
      v-model:visible="confirmBatchDialogVisible"
      message="确定要删除选中的单页吗？"
      @confirm="handleBatchDelete"
      @cancel="confirmBatchDialogVisible = false"
    />
  </div>
</template>

<script>
import api from "@/api"; // 引入已配置好的api实例
import CustomAlert from "@/components/CustomAlert.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import { useRouter } from "vue-router"; // 引入 Vue Router

export default {
  name: "PaGePost",
  components: {
    CustomAlert,
    ConfirmDialog,
  },
  props: {
    isFromArticle: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter();
    const navigateToWriteArticle = () => {
      router.push("/Theme/PaGe");
    };
    const navigateToEditArticle = (id) => {
      router.push(`/Theme/EditWrite?id=${id}`);
    };
    return {
      navigateToWriteArticle,
      navigateToEditArticle,
    };
  },
  data() {
    return {
      posts: [],
      categories: [],
      search: {
        title: "",
        category_id: null,
        author_nickname: "",
      },
      form: {
        id: "",
        title: "",
        cover: "",
        content: "",
        excerpt: "",
        category_id: null,
        author_id: null,
        hide: "n",
        sortop: "n",
        top: "n",
        views: 0,
        allow_remark: "y",
        comnum: 0,
      },
      originalForm: {},
      addDrawerVisible: false,
      editDrawerVisible: false,
      confirmDialogVisible: false,
      confirmBatchDialogVisible: false,
      currentDeleteId: null,
      multipleSelection: [],
      alert: {
        visible: false,
        type: "",
        message: "",
      },
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  computed: {
    formattedCategories() {
      const formatCategories = (categories, parentId = 0, level = 0) => {
        let formatted = [];
        categories
          .filter(category => category.parent_id === parentId)
          .forEach(category => {
            formatted.push({
              ...category,
              label: `${'│  '.repeat(level)}${level > 0 ? '├ ' : ''}${category.name}`,
            });
            formatted = formatted.concat(formatCategories(categories, category.id, level + 1));
          });
        return formatted;
      };
      return formatCategories(this.categories);
    },
  },
  created() {
    this.fetchPosts();
    this.fetchCategories();
  },
  methods: {
    fetchPosts() {
      api
        .get("/api/postslistd", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            page: this.pagination.page,
            pageSize: this.pagination.pageSize,
            title: this.search.title,
            category_id: this.search.category_id,
            author_nickname: this.search.author_nickname,
          },
        })
        .then((response) => {
          const { data, total } = response.data;
          this.posts = data;
          this.pagination.total = total;
        })
        .catch((error) => {
          console.error("获取单页列表失败", error);
          this.showAlert("error", "获取单页列表失败");
        });
    },
    fetchCategories() {
      api
        .get("/api/categories")
        .then((response) => {
          this.categories = response.data.data; // 确保赋值正确
        })
        .catch((error) => {
          console.error("获取分类列表失败", error);
        });
    },
    handlePageChange(newPage) {
      this.pagination.page = newPage;
      this.fetchPosts();
    },
    confirmDelete(id) {
      this.currentDeleteId = id;
      this.confirmDialogVisible = true;
    },
    handleDelete() {
      if (this.currentDeleteId) {
        api
          .delete(`/api/postslist/${this.currentDeleteId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then(() => {
            this.fetchPosts();
            this.showAlert("success", "删除单页成功");
          })
          .catch((error) => {
            console.error("删除单页失败", error);
            this.showAlert("error", "删除单页失败");
          })
          .finally(() => {
            this.confirmDialogVisible = false;
            this.currentDeleteId = null;
          });
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    confirmBatchDelete() {
      this.confirmBatchDialogVisible = true;
    },
    handleBatchDelete() {
      const ids = this.multipleSelection.map((item) => item.id);
      api
        .post(
          "/api/postslists/adminbatch-delete",
          { ids },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(() => {
          this.fetchPosts();
          this.showAlert("success", "批量删除成功");
        })
        .catch((error) => {
          console.error("批量删除失败", error);
          this.showAlert("error", "批量删除失败");
        })
        .finally(() => {
          this.confirmBatchDialogVisible = false;
          this.multipleSelection = [];
        });
    },
    showAlert(type, message) {
      this.alert = {
        visible: true,
        type,
        message,
      };
    },
    selectPost(post) {
      if (this.isFromArticle) {
        this.$emit("post-selected", post);
      }
    },
    confirmSelection() {
      const selectedPosts = this.multipleSelection.map((post) => ({
        id: post.id,
        title: post.title,
      }));
      this.$emit("posts-confirmed", selectedPosts);
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.left-buttons {
  display: flex;
  align-items: center;
}
.search-bar {
  display: flex;
  align-items: center;
}
.table-wrapper {
  overflow-x: auto;
  width: 100%;
}
.drawer-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.custom-pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.el-pagination {
  background-color: #ffffff00;
  padding: 10px;
  border-radius: 5px;
}
.el-pagination .el-pager li:not(.is-active) {
  background-color: #ffffff;
}
.custom-pagination >>> .el-pager .number.is-active {
  background-color: #626aef !important;
}
</style>
