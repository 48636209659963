<!-- src/components/TopNav.vue -->
<template>
  <div>
    <div class="top-nav">
      <div class="tabs">
        <div
          v-for="tab in visibleTabs"
          :key="tab.path"
          :class="['tab', { active: tab.path === $route.path }]"
        >
          <span @click="navigateToTab(tab)">{{ tab.name }}</span>
          <el-icon class="close-icon" @click="closeTab(tab)"><Close /></el-icon>
        </div>
        <el-dropdown v-if="hiddenTabs.length" trigger="click">
          <span class="el-dropdown-link more-tabs">更多</span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="tab in hiddenTabs"
                :key="tab.path"
                @click="navigateToTab(tab)"
              >
                {{ tab.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="right">
        <el-icon class="icon"><QuestionFilled /></el-icon>
        <span class="label">帮助指引</span>
       
        <el-icon class="icon"><RefreshLeft /></el-icon>
        <span class="label"></span>

        <button @click="refreshPage" class="refresh-button">
          <el-icon class="icon"><Refresh /></el-icon>
          <span class="label">刷新</span>
        </button>

        <el-dropdown>
          <span class="el-dropdown-link">
            <!--<el-avatar :size="32" src="https://i.pravatar.cc/300" />-->
            <el-avatar :size="32" :src="avatar" />
            <span class="username">{{ username }}</span>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="viewProfile">个人资料</el-dropdown-item>
              <el-dropdown-item divided @click="logout">退出账号</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { QuestionFilled, RefreshLeft, Refresh, Close } from '@element-plus/icons-vue';
import { ElAvatar, ElDropdown, ElDropdownMenu, ElDropdownItem } from 'element-plus';
import api from '@/api';
import defaultAvatarImage from '@/assets/default-avatar.png'; // 确保你有一个默认头像图片在你的 assets 文件夹中


export default {
  name: 'TopNav',
  components: {
    QuestionFilled,
    RefreshLeft,
    Refresh,
    Close,
    ElAvatar,
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem
  },
  data() {
    return {
      tabs: [],
      maxVisibleTabs: 5,
      username: '',
      avatar: defaultAvatarImage
    };

  },
  computed: {
    visibleTabs() {
      return this.tabs.slice(0, this.maxVisibleTabs);
    },
    hiddenTabs() {
      return this.tabs.slice(this.maxVisibleTabs);
    }
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler(newPath) {
        const existingTab = this.tabs.find(tab => tab.path === newPath);
        if (!existingTab) {
          const routeName = this.$route.meta.title || this.$route.name;
          this.tabs.push({ name: routeName, path: newPath });
        }
      }
    }
  },
  methods: {
 async fetchUserInfo() {
      try {
        const token = localStorage.getItem('token');
        const response = await api.get('/api/Profile/info', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const userInfo = response.data; 
        this.username = userInfo.username;
        this.avatar = userInfo.avatar || defaultAvatarImage;
      } catch (error) {
        console.error('获取用户信息失败', error);
        this.$router.push('/login');
        this.$message.error('获取用户信息失败');
      }
    },




    logout() {
      localStorage.removeItem('token');
      this.$router.push('/login');
    },
    viewProfile() {
     // console.log('查看个人资料');
     this.$router.push('/profile'); // 跳转到个人资料设置页面
    },
    refreshPage() {
      this.$router.go(0);
    },
    navigateToTab(tab) {
      this.$router.push(tab.path);
    },
    closeTab(tab) {
      const tabIndex = this.tabs.findIndex(t => t.path === tab.path);
      if (tabIndex !== -1) {
        this.tabs.splice(tabIndex, 1);
        if (tab.path === this.$route.path) {
          const nextTab = this.tabs[tabIndex] || this.tabs[tabIndex - 1];
          if (nextTab) {
            this.$router.push(nextTab.path);
          } else {
            this.$router.push('/');
          }
        }
      }
    }
  },
  mounted() {
    this.fetchUserInfo();
  }

};




</script>

<style scoped>
.top-nav {
  display: flex;
  justify-content: flex-end; /* 这将使内容靠右显示 */
  align-items: center;
  height: 50px;
  background-color: #ffffff;
  color: #333333;
  padding: 0 20px;
  border-bottom: 1px solid #e0e0e0;
  width: 100%; /* 确保容器宽度 */
}


.tabs {
  display: flex;
  align-items: center;
  flex: 1;
}

.tab {
  display: flex;
  align-items: center;
  padding: 3px 10px;
  margin-right: 10px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  cursor: pointer;
  color: #a5a5a5;
  font-size: 12px;
}

.tab.active {
 /* background-color: #409eff;*/
  color: #626aef;
}



.right {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 14px;
  padding: 0px;
}

.el-dropdown-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none; /* 移除默认的黑色框 */
}

.el-dropdown-link:focus {
  outline: none; /* 确保在聚焦时也没有黑色框 */
}

.username {
  margin-left: 10px;
  margin-right: 20px;
  font-weight: bold;
}

.icon {
  margin-right: 5px; /* 减少图标和文字之间的间距 */
}

.label {
  margin-right: 15px; /* 适当减少文字之间的间距 */
}

button {
  background: none;
  border: none;
  color: #333333;
  font-size: 14px;
  cursor: pointer;
}

button:hover {
  text-decoration: underline;
}

.refresh-button {
  display: flex;
  align-items: center;
  margin-right: 15px;
}


</style>

<!--
<template>
  <div class="top-nav">
    <div class="right">
      <el-icon class="icon"><QuestionFilled /></el-icon>
      <span class="label">帮助指引</span>
      <el-icon class="icon"><House /></el-icon>
      <span class="label">返回首页</span>
      
      <button @click="refreshPage" class="refresh-button">
        <el-icon class="icon"><Refresh /></el-icon>
        <span class="label">刷新</span>
      </button>

      <el-dropdown>
        <span class="el-dropdown-link">
          <el-avatar :size="32" src="https://i.pravatar.cc/300" />
          <span class="username">admin</span>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="viewProfile">个人资料</el-dropdown-item>
            <el-dropdown-item divided @click="logout">退出账号</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { QuestionFilled, House, Refresh } from '@element-plus/icons-vue';
import { ElAvatar, ElDropdown, ElDropdownMenu, ElDropdownItem } from 'element-plus';

export default {
  name: 'TopNav',
  components: {
    QuestionFilled,
    House,
    Refresh,
    ElAvatar,
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem
  },
  methods: {
    logout() {
      localStorage.removeItem('token');
      this.$router.push('/login');
    },
    viewProfile() {
      // 这里可以添加查看个人资料的逻辑
      console.log('查看个人资料');
    },
    refreshPage() {
      this.$router.go(0);
    }
  }
};
</script>

<style scoped>
.top-nav {
  display: flex;
  justify-content: flex-end; /* 这将使内容靠右显示 */
  align-items: center;
  height: 50px;
  background-color: #ffffff;
  color: #333333;
  padding: 0 20px;
  border-bottom: 1px solid #e0e0e0;
  width: 100%; /* 确保容器宽度 */
}

.right {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 14px;
  padding: 0px;
}

.el-dropdown-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none; /* 移除默认的黑色框 */
}

.el-dropdown-link:focus {
  outline: none; /* 确保在聚焦时也没有黑色框 */
}

.username {
  margin-left: 10px;
  margin-right: 20px;
  font-weight: bold;
}

.icon {
  margin-right: 5px; /* 减少图标和文字之间的间距 */
}

.label {
  margin-right: 15px; /* 适当减少文字之间的间距 */
}

button {
  background: none;
  border: none;
  color: #333333;
  font-size: 14px;
  cursor: pointer;
}

button:hover {
  text-decoration: underline;
}

.refresh-button {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
</style>-->

