<template>
  <div>
    <div class="headerr">
      <h1>模板主题</h1>
      <div class="header-buttons">
        <!---<el-button type="warning" @click="openStore">应用商店</el-button>
        <el-button type="success" @click="installTheme"><el-icon><Plus /></el-icon> 安装模板</el-button>-->
      </div>
    </div>
    <!-- Custom Alert -->
    <CustomAlert v-if="alert.visible" :type="alert.type" :message="alert.message" @close="alert.visible = false" />

    <!-- 删除确认对话框 -->
    <ConfirmDialog
      v-model:visible="deleteDialogVisible"
      message="确定要删除这个主题吗？"
      @confirm="confirmDeleteTheme"
      @cancel="resetDeleteData"
    />

    <div class="themes-container">
      <el-row :gutter="20">
        <el-col :span="8" v-for="theme in themes" :key="theme.id">
          <el-card class="theme-card" :body-style="{ padding: '0px' }">
            <div class="theme-header">
              <div :class="['theme-title', { 'active-theme-title': theme.active }]">{{ theme.name }}</div>
              <div class="theme-screenshot-wrapper">
                <img :src="theme.screenshot" alt="主题预览图" class="theme-screenshot" />
              </div>
            </div>
            <div class="theme-body">
              <p style="font-size: 20px; margin-bottom: 15px;">{{ theme.name }}</p>
              <p>版本号: {{ theme.version }}</p>
              <p>开发者: {{ theme.author }}</p>
              <p>{{ theme.description }}</p>
            </div>
            <div class="theme-footer">
              <el-button v-if="!theme.active" type="success" size="mini" @click="activateTheme(theme.id)">启用</el-button>
              <span v-else class="active-text">当前使用中</span>
              <div class="footer-buttons">
                <el-button type="danger" size="mini" @click="openDeleteDialog(theme.id)">删除</el-button>
                <el-button color="#626aef" type="primary" size="mini" @click="goToSettings(theme.id)">设置</el-button>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import api from '@/api'; // 引入已配置好的api实例
import CustomAlert from '@/components/CustomAlert.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
  name: 'ThemeAdmin',
  components: {
    CustomAlert,
    ConfirmDialog
  },
  data() {
    return {
      alert: {
        visible: false,
        type: '',
        message: ''
      },
      themes: [],
      deleteDialogVisible: false,
      themeIdToDelete: null
    };
  },
  created() {
    this.fetchThemes();
  },
  methods: {
    fetchThemes() {
      api.get('/api/Theme', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(response => {
        this.themes = response.data.data.sort((a, b) => b.active - a.active);
      }).catch(error => {
        console.error('提示', error);
        this.showAlert('error', '获取主题失败');
      });
    },
    showAlert(type, message) {
      this.alert.type = type;
      this.alert.message = message;
      this.alert.visible = true;
    },
    goToSettings(themeId) {
    this.$router.push({ name: 'ThemeSettings', params: { id: themeId } });
  },
    openDeleteDialog(themeId) {
      this.themeIdToDelete = themeId;
      this.deleteDialogVisible = true;
    },
    resetDeleteData() {
      this.themeIdToDelete = null;
      this.deleteDialogVisible = false;
    },
    confirmDeleteTheme() {
      if (this.themeIdToDelete) {
        api.delete(`/api/Theme/${this.themeIdToDelete}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }).then(() => {
          this.showAlert('success', '删除主题成功');
          this.resetDeleteData();
          this.fetchThemes();
        }).catch(error => {
          console.error('提示', error);
          this.showAlert('error', '删除主题失败');
          this.resetDeleteData();
        });
      }
    },
    activateTheme(themeId) {
      api.post(`/api/Theme/${themeId}/activate`, {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(() => {
        this.showAlert('success', '激活主题成功');
        this.fetchThemes();
      }).catch(error => {
        console.error('提示', error);
        this.showAlert('error', '激活主题失败');
      });
    },
    installTheme() {
      // 实现安装主题功能
    },
    openStore() {
      // 实现打开应用商店功能
    }
  }
};
</script>

<style>

.headerr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.theme-body p {
  font-size: 14px;
  color: #858796;
}
.header-buttons {
  display: flex;
  gap: 10px;
}
.themes-container {
  padding: 20px;
}
.theme-card {
  border: 1px solid #ebeef5;
  border-radius: 6px;
  margin-bottom: 20px;
}
.theme-header {
  position: relative;
  overflow: hidden;
}
.theme-title {
  font-size: 18px;
  padding: 10px;
  background-color: #f5f5f5;
  color: black;
  text-align: left;
}
.active-theme-title {
  background-color: #1cc88a;
  color: white;
}
.theme-screenshot-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.theme-screenshot {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 3px;
}
.theme-body {
  padding: 10px;
}
.theme-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ebeef5;
}
.footer-buttons {
  display: flex;
  gap: 10px;
}
.active-text {
  color: #1cc88a;
}
</style>
